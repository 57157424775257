import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';

import { useOvermindState, useActions } from 'overmind/index';
import { ROUTE_FINAL_SCREEN } from 'routes';
export default function ResultsContainer(props) {
  const history = useHistory();
  const actions = useActions();
  const overmindState = useOvermindState();
  const { showBack, user } = props;

  useEffect(() => {
    if (user) {
      actions.fetchFeedback();
      let cycle_id = overmindState.activeSurvey.data.surveys[0].id;
      actions.saveFinishedCycle({ cycle_id: cycle_id });
    }
  }, [user, actions]);

  useEffect(() => {
    if (
      !overmindState.feedback.fetching &&
      (overmindState.feedback.data == null ||
        overmindState.feedback.data.length === 0)
    ) {
      return history.replace(ROUTE_FINAL_SCREEN);
    }
  }, [overmindState.feedback.data]);

  const renderFeedback = () => {
    const feedbacks = [];
    overmindState.feedback.data.forEach((feedback) =>
      feedbacks.push({
        name: feedback.name,
        text: feedback.text,
        text_font: feedback.font_size_text,
      })
    );

    return (
      <Grid container direction="column">
        <Grid
          item
          style={{
            marginBottom: '16px',
            fontSize: '34px',
          }}
        >
          Tvoji odgovori upućuju na to da:
        </Grid>

        {feedbacks.map((feedback, ix) => {
          return (
            <ul
              style={{
                marginBottom: '10px',
                fontSize: feedback.text_font
                  ? feedback.text_font + 'px'
                  : '34px',
              }}
            >
              <li>{feedback.text}</li>
            </ul>
          );
        })}
      </Grid>
    );
  };

  return (
    <Box
      style={{
        height: '100vh',
        backgroundColor: props.options['background_color'],
      }}
      translate="no"
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        xs={12}
        style={{
          width: '100vw',
          padding: '10px',
          backgroundColor: '#fdf7f5',
        }}
      >
        {/* Header and content */}
        <Grid item>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              direction="column"
              style={{
                maxWidth: '840px',
                /*border: '1px solid green',*/
              }}
            >
              <Grid item style={{ height: '64px' }}>
                {showBack && (
                  <div
                    onClick={() => props.onBack && props.onBack()}
                    style={{
                      height: '44px',
                      width: '44px',
                      border: '2px solid #581669',
                      borderRadius: '22px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ArrowBackIcon
                      style={{ color: '#581669' }}
                      sx={{ fontSize: 32 }}
                    />
                  </div>
                )}
              </Grid>
              {!overmindState.feedback.fetching &&
                overmindState.feedback &&
                overmindState.feedback.data &&
                overmindState.feedback.data.length > 0 && (
                  <div style={{ paddingTop: '10px' }}>
                    <span
                      style={{
                        fontSize: '34px',
                        fontWeight: 800,
                        color: '#581669',
                      }}
                    >
                      {renderFeedback()}
                    </span>
                  </div>
                )}
            </Grid>
          </Box>
        </Grid>
        {/* Footer */}
        <Grid
          item
          style={{
            marginBottom: '20px',
            /*border: '1px solid red',*/
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '10px',
            }}
          >
            <Grid
              container
              direction="column"
              style={{
                maxWidth: '540px',
                backgroundColor: props.options['background_color'],
                /*border: '1px solid green',*/
              }}
            >
              <Grid item style={{ height: '110px' }}>
                <>
                  <div
                    onClick={() => {
                      history.replace(ROUTE_FINAL_SCREEN);
                    }}
                    style={{
                      height: '44px',
                      width: '100%',
                      border: '2px solid ' + props.options['color'],
                      borderRadius: '22px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: props.options['color'],
                      color: 'white',
                      cursor: 'pointer',
                    }}
                  >
                    <span>Dalje</span>
                  </div>
                </>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
