import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { auth, signInWithGoogle } from 'firebase';
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
import 'assets/styles.css';
import SignInUpContainer from 'components/SignInUpContainer';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import {
  ROUTE_LOGIN_FORM,
  ROUTE_QUESTIONNAIRE,
  ROUTE_REGISTER,
  ROUTE_REGISTER_FORM,
  ROUTE_PRIVACY_POLICY,
  ROUTE_LOCK_SCREEN,
} from 'routes';
import { useActions } from 'overmind/index';
import { useOvermindState } from 'overmind/index';
import { Button } from '@mui/material';
import GoogleSignInButton from 'components/GoogleSignInButton';
import MailSignInButton from 'components/MailSignInButton';
import LoginForm from 'components/LoginForm';
import RegisterForm from 'components/RegisterForm';

export default function LoginPage() {
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const actions = useActions();
  const overmindState = useOvermindState();
  const [activeUnAndPwdForm, setActiveUnAndPwdForm] = useState(false);
  const [emailSigninButtonHidden, setEmailSigninButtonHidden] = useState(false);
  const [registerForm, setRegisterForm] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (loading) setShowSpinner(true);
    if (!loading) setShowSpinner(false);
  }, [user, loading]);

  useEffect(() => {
    if (!loading && user) {
      actions.setUser(user);
      user.getIdToken(true).then((token) => {
        window.localStorage.setItem('access_token', token);
        // token to be used to call our API
        actions.fetchUserProfile().then(() => {
          if (overmindState.isUser16 || overmindState.isParentConsentReceived) {
            history.replace(ROUTE_QUESTIONNAIRE);
          } else if (
            !overmindState.isUser16 &&
            !overmindState.isParentConsentReceived
          ) {
            history.replace(ROUTE_LOCK_SCREEN);
          }
        });
        history.replace(ROUTE_PRIVACY_POLICY);
      });
    }
  }, [user, loading]);

  useEffect(() => {
    if (overmindState.profile && overmindState.profile.data) {
      history.replace(ROUTE_PRIVACY_POLICY);
    }
  }, [overmindState.profile.data]);

  useEffect(() => {
    // Confirm the link is a sign-in with email link.
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      setShowSpinner(true);
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          setShowSpinner(false);
        })
        .catch((error) => {
          console.log(error);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, []);

  const setEmailLogin = () => {
    setActiveUnAndPwdForm(true);
    setEmailSigninButtonHidden(true);
  };

  return (
    <SignInUpContainer>
      <div
        style={{
          maxWidth: '90vw',
          paddingLeft: '20px',
          paddingRight: '20px',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div
          style={{
            textAlign: 'left',
            fontSize: '44px',
            color: 'rgb(169, 235, 222)',
            fontWeight: 800,
            display: 'flex',
            flexDirection: 'column',
            flexBasis: 'auto',
            alignItems: 'stretch',
            position: 'relative',
          }}
        >
          <div>Stress load</div>
        </div>
        {!registerForm ? (
          <>
            <div style={{ marginTop: '30vh' }}></div>
            {activeUnAndPwdForm && (
              <div
                style={{
                  marginBottom: '20px',
                  display: 'flex',
                  flexBasis: 'auto',
                  alignItems: 'stretch',
                  position: 'relative',
                  fontSize: '22px',
                  fontWeight: 700,
                }}
              >
                <LoginForm />
              </div>
            )}
            <GoogleSignInButton
              text={'Prijavi se s Google računom'}
              onClick={signInWithGoogle}
              showSpinner={showSpinner}
            />
            {!emailSigninButtonHidden && (
              <MailSignInButton
                text={'Prijavi se s E-mail adresom'}
                onClick={() => setEmailLogin()}
                showSpinner={showSpinner}
              />
            )}
            {/* <div
              style={{
                marginTop: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
              }}
            >
              Još nemaš račun?{' '}
              <Link
                onClick={() => setRegisterForm(true)}
                style={{
                  color: 'blue',
                  textDecoration: 'underline',
                  paddingLeft: '4px',
                }}
              >
                Registriraj se
              </Link>
              .
            </div> */}
          </>
        ) : (
          <>
            <div style={{ marginTop: '30vh' }}></div>
            {activeUnAndPwdForm && (
              <div
                style={{
                  marginBottom: '20px',
                  display: 'flex',
                  flexBasis: 'auto',
                  alignItems: 'stretch',
                  position: 'relative',
                  fontSize: '22px',
                  fontWeight: 700,
                }}
              >
                <RegisterForm
                  onClick={() => history.push(ROUTE_REGISTER_FORM)}
                />
              </div>
            )}

            <GoogleSignInButton
              text={'Registriraj se s Google računom'}
              onClick={signInWithGoogle}
            />
            {/* {!emailSigninButtonHidden && (
              <MailSignInButton
                text={'Registriraj se s Email adresom'}
                onClick={() => setEmailLogin()}
              />
            )} */}
            {/* <div
              style={{
                marginTop: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
              }}
            >
              Već imaš račun?{' '}
              <Link
                onClick={() => setRegisterForm(false)}
                style={{
                  color: 'blue',
                  textDecoration: 'underline',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                }}
              >
                Prijavi se.
              </Link>
            </div> */}
          </>
        )}
      </div>
    </SignInUpContainer>
  );
}
