import React, { useEffect, useState } from 'react';
import { useActions } from 'overmind/index';
import Highlighter from 'react-highlight-words';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Male, Female } from 'assets/imagesList';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

import { styled } from '@mui/material/styles';

function valuetext(value) {
  return `${value}`;
}

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: 'rgb(169, 235, 222)',
  height: 6,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
}));

export default function QuestionContainer(props) {
  const { showBack, options, section, showNext, showFooter } = props;
  const [selectedValue, setSelectedValue] = useState(undefined);
  const [selectedQuestion, setSelectedQuestion] = useState(undefined);
  const [shake, setShake] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [isMonthSelected, setIsMonthSelected] = useState();
  const [isYearSelected, setIsYearSelected] = useState();
  const actions = useActions();
  const [isValueValid, setIsValueValid] = useState(false);
  const [valueIsInsideMinAndMax, setValueIsInsideMinAndMax] = useState(false);
  const [selectedText, setSelectedText] = useState();
  const [firstValue, setFirstValue] = useState(undefined);
  const [secondValue, setSecondValue] = useState(undefined);
  const [multipleSelection, setMultipleSelection] = useState([]);
  const [multipleSelectionValues, setMultipleSelectionValues] = useState([]);

  useEffect(() => {
    // resets all state values when props change
    setSelectedValue(undefined);
    setSelectedQuestion(undefined);
    setSelectedText(undefined);
    setFirstValue(undefined);
    setSecondValue(undefined);
    setMultipleSelection([]);
    setMultipleSelectionValues([]);
    setIsValueValid(false);
  }, [props]);

  const shakeIt = () => {
    // begins to shake
    setShake(true);

    // stops to shake after 1 seconds
    setTimeout(() => setShake(false), 1000);
  };

  const handleSelectChange = (selection) => {
    setSelectedValue(selection.value);
    shakeIt();
    let timer = setTimeout(async () => {
      try {
        actions.saveAnswer({
          value: selection.value,
          text: selection.text,
          customQuestion: null,
          question_id: props.options['id'],
        });

        props.onNext &&
          props.onNext({
            value: selection.value,
            text: selection.text,
          });
      } catch (e) {
        console.log(e);
      }
    }, 500);
    return () => clearTimeout(timer);
  };

  const renderSelections = () => {
    const selections = [];
    Object.keys(options).forEach((key) => {
      if (key.startsWith('text_')) {
        if (options[key] != null) {
          selections.push({
            text: options[key],
            value: options[key.replace('text', 'value')],
          });
        }
      }
    });
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          direction="column"
          style={{
            maxWidth: '150px',
          }}
        >
          {selections.map((selection, ix) => {
            return (
              <Grid item key={ix} style={{ marginBottom: '16px' }}>
                <div
                  onClick={() => {
                    handleSelectChange(selection);
                  }}
                  style={{
                    minHeight: '38px',
                    width: '100%',
                    border: '2px solid ' + props.options['color'],
                    backgroundColor:
                      selectedValue === selection.value
                        ? props.options['color']
                        : 'inherit',
                    borderRadius: '19px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color:
                      selectedValue === selection.value
                        ? 'white'
                        : props.options['color'],
                    cursor: 'pointer',
                    padding: '8px',
                    textAlign: 'center',
                  }}
                  className={
                    shake && selectedValue === selection.value ? `shake` : null
                  }
                >
                  <span>{selection.text}</span>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const handleMultipleSelectChange = (selection) => {
    let newMultipleSelections = [...multipleSelection];
    let newMultipleSelectionsValues = [...multipleSelectionValues];
    if (selection != null) {
      var value_index = newMultipleSelectionsValues.indexOf(selection.value);
      if (value_index !== -1) {
        newMultipleSelections.splice(value_index, 1);
        newMultipleSelectionsValues.splice(value_index, 1);
      } else {
        newMultipleSelections.push(selection);
        newMultipleSelectionsValues.push(selection.value);
      }
    }
    setMultipleSelection(newMultipleSelections);
    setMultipleSelectionValues(newMultipleSelectionsValues);
  };

  const renderMultipleSelections = () => {
    const selections = [];
    Object.keys(options).forEach((key) => {
      if (key.startsWith('text_')) {
        if (options[key] != null) {
          selections.push({
            text: options[key],
            value: options[key.replace('text', 'value')],
          });
        }
      }
    });
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          direction="column"
          style={{
            maxWidth: '150px',
          }}
        >
          {selections.map((selection, ix) => {
            return (
              <Grid item key={ix} style={{ marginBottom: '16px' }}>
                <div
                  onClick={() => {
                    handleMultipleSelectChange(selection);
                  }}
                  style={{
                    minHeight: '38px',
                    width: '100%',
                    border: '2px solid ' + props.options['color'],
                    backgroundColor: multipleSelectionValues.includes(
                      selection.value
                    )
                      ? props.options['color']
                      : 'inherit',
                    borderRadius: '19px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: multipleSelectionValues.includes(selection.value)
                      ? 'white'
                      : props.options['color'],
                    cursor: 'pointer',
                    padding: '8px',
                    textAlign: 'center',
                  }}
                  className={
                    shake && multipleSelectionValues.includes(selection.value)
                      ? `shake`
                      : null
                  }
                >
                  <span>{selection.text}</span>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const handleGenderChange = (selection) => {
    setSelectedValue(selection);
    shakeIt();
    let timer = setTimeout(async () => {
      try {
        actions.saveAnswer({
          value: selection,
          text: null,
          customQuestion: null,
          question_id: props.options['id'],
        });

        props.onNext &&
          props.onNext({
            value: selection,
            text: null,
          });
      } catch (e) {
        console.log(e);
      }
    }, 500);
    return () => clearTimeout(timer);
  };

  const renderGender = () => {
    const selections = [];
    Object.keys(options).forEach((key) => {
      if (key.startsWith('text_')) {
        if (options[key] != null) {
          selections.push(options[key]);
        }
      }
    });
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          direction="row"
          style={{
            maxWidth: '300px',
          }}
          justifyContent="center"
          alignItems="center"
        >
          {selections.map((selection, ix) => {
            return (
              <Grid
                item
                key={ix}
                style={{ marginBottom: '16px', marginRight: '16px' }}
              >
                <div
                  onClick={() => handleGenderChange(selection)}
                  style={{
                    height: '116px',
                    width: '116px',
                    border: '2px solid ' + props.options['color'],
                    backgroundColor:
                      selectedValue === selection
                        ? props.options['color']
                        : 'inherit',
                    borderRadius: '19px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color:
                      selectedValue === selection
                        ? 'white'
                        : props.options['color'],
                    cursor: 'pointer',
                  }}
                  className={
                    shake && selectedValue === selection ? `shake` : null
                  }
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      {selection.startsWith('M') ? <Male /> : <Female />}
                    </Grid>
                    <Grid item>
                      <span>{selection}</span>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const renderDate = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          direction="row"
          style={{
            maxWidth: '300px',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ marginBottom: '16px' }}>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <MobileDatePicker
                inputFormat="DD.MM.yyyy"
                value={selectedValue}
                onChange={(val) => {
                  setSelectedValue(val);
                }}
                input={<OutlinedInput />}
                renderInput={(params) => <TextField {...params} />}
                disableFuture={true}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handleChangeMonth = (event) => {
    setSelectedMonth(event.target.value);
    if (selectedYear) {
      setSelectedValue(event.target.value);
    }
    setIsMonthSelected(true);
  };

  const handleChangeYear = (event) => {
    setSelectedYear(event.target.value);
    if (selectedMonth) {
      setSelectedValue(event.target.value);
    }
    setIsYearSelected(true);
  };

  const getMinAndMaxYear = (options) => {
    const minYear = options.min_value;
    const maxYear = options.max_value;
    let years = [];

    for (let year = minYear; year <= maxYear; year++) {
      years.push(<MenuItem value={`${year}`}>{year}</MenuItem>);
    }
    return years;
  };

  const renderMonth = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          direction="row"
          style={{
            maxWidth: '300px',
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <FormHelperText style={{ color: props.options['color'] }}>
              MJESEC
            </FormHelperText>

            <Select
              value={selectedMonth}
              onChange={(event) => {
                handleChangeMonth(event);
              }}
              style={{
                border: '2px solid ' + props.options['color'],
                backgroundColor: isMonthSelected
                  ? props.options['color']
                  : 'inherit',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: isMonthSelected ? 'white' : props.options['color'],
                cursor: 'pointer',
              }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value={1}>Siječanj</MenuItem>
              <MenuItem value={2}>Veljača</MenuItem>
              <MenuItem value={3}>Ožujak</MenuItem>
              <MenuItem value={4}>Travanj</MenuItem>
              <MenuItem value={5}>Svibanj</MenuItem>
              <MenuItem value={6}>Lipanj</MenuItem>
              <MenuItem value={7}>Srpanj</MenuItem>
              <MenuItem value={8}>Kolovoz</MenuItem>
              <MenuItem value={9}>Rujan</MenuItem>
              <MenuItem value={10}>Listopad</MenuItem>
              <MenuItem value={11}>Studeni</MenuItem>
              <MenuItem value={12}>Prosinac</MenuItem>
            </Select>
          </FormControl>
          {options && (
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <FormHelperText style={{ color: props.options['color'] }}>
                GODINA
              </FormHelperText>
              <Select
                value={selectedYear}
                onChange={(event) => {
                  handleChangeYear(event);
                }}
                style={{
                  border: '2px solid ' + props.options['color'],
                  backgroundColor: isYearSelected
                    ? props.options['color']
                    : 'inherit',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: isYearSelected ? 'white' : props.options['color'],
                  cursor: 'pointer',
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {getMinAndMaxYear(options)}
              </Select>
            </FormControl>
          )}
        </Grid>
      </Box>
    );
  };

  const checkIfValueIsInsideMinAndMax = (e, options) => {
    let value = parseInt(e.target.value);
    if (
      (options.max_value &&
        options.min_value &&
        (value > options.max_value || value < options.min_value)) ||
      (options.max_value && value > options.max_value && !options.min_value) ||
      (options.min_value && value < options.min_value && !options.max_value)
    ) {
      setValueIsInsideMinAndMax(false);
    } else {
      setValueIsInsideMinAndMax(true);
    }
  };

  const renderNumber = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          direction="row"
          style={{
            maxWidth: '300px',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ marginBottom: '16px' }}>
            {options && (
              <>
                <input
                  type="text"
                  className="onboarding__textBox"
                  value={selectedValue == null ? '' : selectedValue}
                  onChange={(e) => {
                    setSelectedValue(e.target.value.replace(/[^\d]/, ''));
                    checkIfValueIsInsideMinAndMax(e, options);
                  }}
                  placeholder=""
                  style={{
                    border: '2px solid ' + props.options['color'],
                    backgroundColor: props.options['background_color'],
                    color: props.options['color'],
                  }}
                />
                {(options.max_value || options.max_value == 0) &&
                  (options.min_value || options.min_value == 0) &&
                  (selectedValue > options.max_value ||
                    selectedValue < options.min_value) && (
                    <div
                      style={{
                        color: 'red',
                      }}
                    >
                      Unesite vrijednost između {options.min_value} i{' '}
                      {options.max_value}.
                    </div>
                  )}
                {(options.max_value || options.max_value == 0) &&
                  selectedValue > options.max_value &&
                  !options.min_value &&
                  options.min_value !== 0 && (
                    <div
                      style={{
                        color: 'red',
                      }}
                    >
                      Unesite vrijednost manju od {options.max_value}.
                    </div>
                  )}
                {(options.min_value || options.min_value == 0) &&
                  selectedValue < options.min_value &&
                  !options.max_value &&
                  options.max_value !==
                    0(
                      <div
                        style={{
                          color: 'red',
                        }}
                      >
                        Unesite vrijednost veću od {options.min_value}.
                      </div>
                    )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handleDecimalInput = (amount) => {
    setIsValueValid(false);
    setSelectedValue(amount);

    if (!amount || amount.match(/^\d((\.|\,)\d{1,2}){0,1}$/)) {
      setIsValueValid(true);
    }
  };

  const checkIfDecimalValueIsInsideMinAndMax = (value, options) => {
    if (
      (options.max_value &&
        options.min_value &&
        (value > options.max_value || value < options.min_value)) ||
      (options.max_value && value > options.max_value && !options.min_value) ||
      (options.min_value && value < options.min_value && !options.max_value)
    ) {
      setValueIsInsideMinAndMax(false);
    } else {
      setValueIsInsideMinAndMax(true);
    }
  };

  const renderDecimal = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          direction="row"
          style={{
            maxWidth: '300px',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ marginBottom: '16px' }}>
            {options && (
              <>
                <input
                  type="text"
                  className={
                    isValueValid
                      ? 'onboarding__textBox'
                      : 'onboarding__textBox_error'
                  }
                  onChange={(e) => {
                    let value = e.target.value.replace(',', '.');
                    handleDecimalInput(value);
                    checkIfDecimalValueIsInsideMinAndMax(value, options);
                  }}
                  placeholder=""
                  value={selectedValue == null ? '' : selectedValue}
                />
                {(options.max_value || options.max_value == 0) &&
                  (options.min_value || options.min_value == 0) &&
                  (selectedValue > options.max_value ||
                    selectedValue < options.min_value) && (
                    <div
                      style={{
                        color: 'red',
                      }}
                    >
                      Unesite vrijednost između {options.min_value} i{' '}
                      {options.max_value}.
                    </div>
                  )}
                {(options.max_value || options.max_value == 0) &&
                  selectedValue > options.max_value &&
                  !options.min_value &&
                  options.min_value !== 0 && (
                    <div
                      style={{
                        color: 'red',
                      }}
                    >
                      Unesite vrijednost manju od {options.max_value}.
                    </div>
                  )}
                {(options.min_value || options.min_value == 0) &&
                  selectedValue < options.min_value &&
                  !options.max_value &&
                  options.max_value !==
                    0(
                      <div
                        style={{
                          color: 'red',
                        }}
                      >
                        Unesite vrijednost veću od {options.min_value}.
                      </div>
                    )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handleScalePicker = (e, marks) => {
    let selectedValue = e.target.value;
    setSelectedValue(selectedValue);
    for (let i = 0; i < marks.length; i++) {
      if (marks[i].value == selectedValue) {
        setSelectedText(marks[i].text);
      }
    }
  };

  const renderScale = () => {
    const marks = [];
    Object.keys(options).forEach((key) => {
      if (key.startsWith('value_')) {
        if (options[key] != null) {
          let text_number = `text_${key.substr(6, 6)}`;
          marks.push({
            value: parseInt(options[key], 10),
            label: options[key] + '',
            text: options[text_number],
          });
        }
      }
    });

    if (selectedValue == null) {
      setSelectedValue(marks[0].value);
    }

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          direction="row"
          style={{
            maxWidth: '300px',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ marginBottom: '16px' }}>
            <Box sx={{ width: 270 }}>
              <CustomSlider
                value={selectedValue}
                defaultValue={marks[0].value}
                step={1}
                min={marks[0].value}
                max={marks[marks.length - 1].value}
                marks={marks}
                getAriaValueText={valuetext}
                onChange={(e) => {
                  handleScalePicker(e, marks);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const handleNext = () => {
    if (
      showNext &&
      selectedValue &&
      options.input_type !== 'SELECT' &&
      options.input_type !== 'GENDER' &&
      options.input_type !== 'DECIMAL' &&
      options.input_type !== 'NUMBER'
    ) {
      return true;
    } else if (
      options.input_type !== 'SELECT' &&
      options.input_type !== 'GENDER' &&
      options.input_type !== 'DECIMAL' &&
      showNext &&
      selectedValue &&
      valueIsInsideMinAndMax
    ) {
      return true;
    } else if (
      options.input_type == 'SCALE' &&
      showNext &&
      (selectedValue || selectedValue == 0)
    ) {
      return true;
    } else if (
      options.input_type == 'TWO_FIELD' &&
      showNext &&
      firstValue &&
      secondValue
    ) {
      return true;
    } else if (
      options.input_type == 'MULTIPLE_SELECT' &&
      showNext &&
      multipleSelection &&
      multipleSelection.length > 0
    ) {
      return true;
    } else if (
      options.input_type == 'DECIMAL' &&
      showNext &&
      selectedValue &&
      isValueValid &&
      valueIsInsideMinAndMax
    ) {
      return true;
    }

    return false;
  };

  const renderCustom = () => {
    const selections = [];
    Object.keys(options).forEach((key) => {
      if (key.startsWith('text_')) {
        if (options[key] != null) {
          selections.push({
            text: options[key],
            value: options[key.replace('text', 'value')],
          });
        }
      }
    });

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          direction="column"
          style={{
            maxWidth: '150px',
          }}
        >
          {selections.map((selection, ix) => {
            return (
              <Grid item key={ix} style={{ marginBottom: '16px' }}>
                <div
                  onClick={() => {
                    if (selectedQuestion) {
                      actions.saveAnswer({
                        value: selection.value,
                        text: selection.text,
                        customQuestion: selectedQuestion,
                        question_id: props.options['id'],
                      });
                      props.onNext &&
                        props.onNext({
                          value: selection.value,
                          text: selection.text,
                        });
                    } else {
                      shakeIt();
                    }
                  }}
                  style={{
                    minHeight: '38px',
                    width: '100%',
                    border: '2px solid ' + props.options['color'],
                    backgroundColor:
                      selectedValue === selection.value
                        ? props.options['color']
                        : 'inherit',
                    borderRadius: '19px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color:
                      selectedValue === selection.value
                        ? 'white'
                        : props.options['color'],
                    cursor: 'pointer',
                    padding: '8px',
                    textAlign: 'center',
                  }}
                  className={
                    shake && selectedValue !== selection.value ? `shake` : null
                  }
                >
                  <span>{selection.text}</span>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const renderText = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          direction="row"
          style={{
            maxWidth: '300px',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ marginBottom: '16px' }}>
            {options && (
              <>
                <input
                  type="text"
                  className="onboarding__textBox"
                  value={selectedValue == null ? '' : selectedValue}
                  onChange={(e) => {
                    setSelectedValue(e.target.value);
                  }}
                  placeholder=""
                  style={{
                    border: '2px solid ' + props.options['color'],
                    backgroundColor: props.options['background_color'],
                    color: props.options['color'],
                  }}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderTwoField = () => {
    return (
      <Box display="flex">
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <FormControl sx={{ m: 1, width: '15ch' }}>
            <TextField
              label={options.text_1}
              size="small"
              onChange={(event) =>
                setFirstValue(event.target.value.replace(/[^\d]/, ''))
              }
              value={firstValue == null ? '' : firstValue}
              color="secondary"
              focused
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: '15ch' }}>
            <TextField
              label={options.text_2}
              size="small"
              onChange={(event) =>
                setSecondValue(event.target.value.replace(/[^\d]/, ''))
              }
              value={secondValue == null ? '' : secondValue}
              color="secondary"
              focused
            />
          </FormControl>
        </Grid>
      </Box>
    );
  };

  const handleSubtext = (subtext) => {
    const subtext_array = subtext.split('?');

    return (
      <ol>
        {subtext_array.map((item, i) => item && <li key={i}>{item}?</li>)}
      </ol>
    );
  };

  return (
    <Box
      style={{
        height: '100vh',
        backgroundColor: props.options['background_color'],
      }}
      translate="no"
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        xs={12}
        style={{
          width: '100vw',
          padding: '10px',
          backgroundColor: props.options['background_color'],
        }}
      >
        {/* Header and content */}
        <Grid item>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              direction="column"
              style={{
                maxWidth: '840px',
                /*border: '1px solid green',*/
              }}
            >
              {section?.name && (
                <Grid item>
                  <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                    <span
                      style={{
                        fontSize: '24px',
                        fontWeight: 800,
                        color: props.options['color'],
                      }}
                    >
                      {section.name}
                    </span>
                  </div>
                </Grid>
              )}
              <Grid item style={{ height: '64px' }}>
                {showBack && (
                  <div
                    onClick={() => props.onBack && props.onBack()}
                    style={{
                      height: '44px',
                      width: '44px',
                      border: '2px solid ' + props.options['color'],
                      borderRadius: '22px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ArrowBackIcon
                      style={{ color: props.options['color'] }}
                      sx={{ fontSize: 32 }}
                    />
                  </div>
                )}
              </Grid>
              <Grid item>
                <div style={{ paddingTop: '80px' }}>
                  <span
                    style={{
                      fontSize: options.font_size_text
                        ? options.font_size_text + 'px'
                        : '34px',
                      fontStyle: options.font_style_text
                        ? options.font_style_text
                        : 'normal',
                      fontWeight: 800,
                      color: props.options['color'],
                    }}
                  >
                    <Highlighter
                      highlightClassName="YourHighlightClass"
                      searchWords={
                        options.highlight_text
                          ? options.highlight_text.split(',')
                          : []
                      }
                      autoEscape={true}
                      textToHighlight={options.text}
                    />{' '}
                    {options.input_type === 'CUSTOM' && (
                      <input
                        type="text"
                        className="onboarding__textBox"
                        value={selectedQuestion == null ? '' : selectedQuestion}
                        onChange={(e) => {
                          setSelectedQuestion(e.target.value);
                        }}
                        placeholder=""
                      />
                    )}
                  </span>
                </div>
              </Grid>
              {options.sub_text && options.sub_text !== '-' && (
                <Grid item>
                  <div
                    style={{
                      paddingTop: '10px',
                    }}
                  >
                    {!options.sub_text.includes('?') ? (
                      <span
                        style={{
                          fontSize: options.font_size_subtext
                            ? options.font_size_subtext + 'px'
                            : '18px',
                          fontWeight: 400,
                          fontStyle: options.font_style_subtext
                            ? options.font_style_subtext
                            : 'normal',
                          color: props.options['color'],
                        }}
                      >
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={
                            options.highlight_text
                              ? options.highlight_text.split(',')
                              : []
                          }
                          autoEscape={true}
                          textToHighlight={options.sub_text}
                        />
                      </span>
                    ) : (
                      <>{handleSubtext(options.sub_text)}</>
                    )}
                  </div>
                </Grid>
              )}
              <Grid
                item
                style={{ backgroundColor: props.options['background_color'] }}
              >
                <div style={{ paddingTop: '20px' }}>
                  {options.input_type === 'SELECT' && renderSelections()}
                  {options.input_type === 'GENDER' && renderGender()}
                  {options.input_type === 'DATE' && renderDate()}
                  {options.input_type === 'NUMBER' && renderNumber()}
                  {options.input_type === 'SCALE' && renderScale()}
                  {options.input_type === 'MONTH' && renderMonth()}
                  {options.input_type === 'DECIMAL' && renderDecimal()}
                  {options.input_type === 'CUSTOM' && renderCustom()}
                  {options.input_type === 'TEXT' && renderText()}
                  {options.input_type === 'TWO_FIELD' && renderTwoField()}
                  {options.input_type === 'MULTIPLE_SELECT' &&
                    renderMultipleSelections()}
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Footer */}
        {showFooter && (
          <Grid
            item
            style={{
              marginBottom: '20px',
              /*border: '1px solid red',*/
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px',
              }}
            >
              <Grid
                container
                direction="column"
                style={{
                  maxWidth: '540px',
                  backgroundColor: props.options['background_color'],
                  /*border: '1px solid green',*/
                }}
              >
                <Grid item style={{ height: '110px' }}>
                  {handleNext() && (
                    <>
                      <div
                        onClick={() => {
                          if (selectedMonth && selectedYear) {
                            actions.saveAnswer({
                              value: `${selectedMonth}-${selectedYear}`,
                              text: null,
                              customQuestion: null,
                              question_id: props.options['id'],
                            });
                            setSelectedMonth(null);
                            setSelectedYear(null);
                          } else if (firstValue && secondValue) {
                            actions.saveAnswer({
                              value: `${firstValue}-${secondValue}`,
                              text: null,
                              customQuestion: null,
                              question_id: props.options['id'],
                            });
                            setFirstValue(null);
                            setSecondValue(null);
                          } else if (
                            multipleSelection &&
                            multipleSelection.length !== 0
                          ) {
                            let values = multipleSelection[0].value;
                            let texts = multipleSelection[0].text;
                            if (multipleSelection.length > 0) {
                              for (
                                let i = 1;
                                i < multipleSelection.length;
                                i++
                              ) {
                                values =
                                  values + ` - ${multipleSelection[i].value}`;
                                texts =
                                  texts + ` - ${multipleSelection[i].text}`;
                              }
                            }
                            actions.saveAnswer({
                              value: values,
                              text: texts,
                              customQuestion: null,
                              question_id: props.options['id'],
                            });
                            setMultipleSelection([]);
                          } else {
                            actions.saveAnswer({
                              value: selectedValue,
                              text: selectedText,
                              customQuestion: null,
                              question_id: props.options['id'],
                            });
                          }
                          props.onNext &&
                            props.onNext({
                              value: selectedValue,
                              text: selectedText,
                            });
                          setValueIsInsideMinAndMax(false);
                          setSelectedValue(null);
                        }}
                        style={{
                          height: '44px',
                          width: '100%',
                          border: '2px solid ' + props.options['color'],
                          borderRadius: '22px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: props.options['color'],
                          color: 'white',
                          cursor: 'pointer',
                        }}
                      >
                        <span>Dalje</span>
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
