import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useActions } from 'overmind/index';
import { useOvermindState } from 'overmind/index';
import { ROUTE_QUESTIONNAIRE} from 'routes';
import Box from '@mui/material/Box';
import 'assets/styles.css';
import Grid from '@mui/material/Grid';

export default function LockScreen() {
  const overmindState = useOvermindState();
  const actions = useActions();
  const history = useHistory();

  useEffect(() => {
    actions.fetchUserProfile().then(() => {
      if (overmindState.isUser16 || overmindState.isParentConsentReceived) {
        history.replace(ROUTE_QUESTIONNAIRE);
      }   
    });
    
  }, []);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Grid
        container
        direction="column"
        xs={12}
        style={{
          maxWidth: '840px',
          paddingTop: '100px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <div
          style={{
            fontSize: '22px',
            color: 'rgb(88, 22, 105)',
            fontWeight: 800,
            textAlign: 'center',
          }}
        >
          Roditelj / Skrbnik nije dao privolu / suglasnost
        </div>
      </Grid>
    </Box>
  );
}
