import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { auth, signInWithGoogle } from 'firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import 'assets/styles.css';
import SignInUpContainer from 'components/SignInUpContainer';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import {
  ROUTE_LOGIN_FORM,
  ROUTE_QUESTIONNAIRE,
  ROUTE_REGISTER,
  ROUTE_REGISTER_FORM,
} from 'routes';
import { useActions } from 'overmind/index';
import { useOvermindState } from 'overmind/index';
import { Button } from '@mui/material';
import GoogleSignInButton from 'components/GoogleSignInButton';
import MailSignInButton from 'components/MailSignInButton';
import LoginForm from 'components/LoginForm';
import RegisterForm from 'components/RegisterForm';
export default function LoginPage() {
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const actions = useActions();
  const overmindState = useOvermindState();
  const [activeUnAndPwdForm, setActiveUnAndPwdForm] = useState(false);
  const [emailSigninButtonHidden, setEmailSigninButtonHidden] = useState(false);
  const [registerForm, setRegisterForm] = useState(false);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      actions.setUser(user);
      if (user.profile == null || user.profile.data == null) {
        actions.fetchUserDetails(user.uid);
        history.replace(ROUTE_QUESTIONNAIRE);
      } else {
        history.replace(ROUTE_QUESTIONNAIRE);
      }
    }
  }, [user, loading]);

  useEffect(() => {
    if (overmindState.profile && overmindState.profile.data) {
      history.replace(ROUTE_QUESTIONNAIRE);
    }
  }, [overmindState.profile.data]);

  const setEmailLogin = () => {
    setActiveUnAndPwdForm(true);
    setEmailSigninButtonHidden(true);
  };

  return (
    <SignInUpContainer>
      <div
        style={{
          maxWidth: '90vw',
          paddingLeft: '20px',
          paddingRight: '20px',
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div
          style={{
            textAlign: 'left',
            fontSize: '44px',
            color: 'rgb(169, 235, 222)',
            fontWeight: 800,
            display: 'flex',
            flexDirection: 'column',
            flexBasis: 'auto',
            alignItems: 'stretch',
            position: 'relative',
          }}
        >
          <div>Stress load</div>
        </div>
        {!registerForm ? (
          <>
            <div style={{ marginTop: '30vh' }}></div>
            {activeUnAndPwdForm && (
              <div
                style={{
                  marginBottom: '20px',
                  display: 'flex',
                  flexBasis: 'auto',
                  alignItems: 'stretch',
                  position: 'relative',
                  fontSize: '22px',
                  fontWeight: 700,
                }}
              >
                <LoginForm onClick={signInWithGoogle} />
              </div>
            )}
            <GoogleSignInButton
              text={'Prijavi se s Google računom'}
              onClick={signInWithGoogle}
            />
            {!emailSigninButtonHidden && (
              <MailSignInButton
                text={'Prijavi se s Email adresom'}
                onClick={() => setEmailLogin()}
              />
            )}
            <div
              style={{
                marginTop: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
              }}
            >
              Još nemaš račun?{' '}
              <Link
                onClick={() => setRegisterForm(true)}
                style={{
                  color: 'blue',
                  textDecoration: 'underline',
                  paddingLeft: '4px',
                }}
              >
                Registriraj se
              </Link>
              .
            </div>
          </>
        ) : (
          <>
            <div style={{ marginTop: '30vh' }}></div>
            {activeUnAndPwdForm && (
              <div
                style={{
                  marginBottom: '20px',
                  display: 'flex',
                  flexBasis: 'auto',
                  alignItems: 'stretch',
                  position: 'relative',
                  fontSize: '22px',
                  fontWeight: 700,
                }}
              >
                <RegisterForm
                  onClick={() => history.push(ROUTE_REGISTER_FORM)}
                />
              </div>
            )}

            <GoogleSignInButton
              text={'Registriraj se s Google računom'}
              onClick={signInWithGoogle}
            />
            {!emailSigninButtonHidden && (
              <MailSignInButton
                text={'Registriraj se s Email adresom'}
                onClick={() => setEmailLogin()}
              />
            )}
            <div
              style={{
                marginTop: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
              }}
            >
              Već imaš račun?{' '}
              <Link
                onClick={() => setRegisterForm(false)}
                style={{
                  color: 'blue',
                  textDecoration: 'underline',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                }}
              >
                Prijavi se.
              </Link>
            </div>
          </>
        )}
      </div>
    </SignInUpContainer>
  );
}
