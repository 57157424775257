import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { auth, registerWithEmailAndPassword } from 'firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import 'assets/styles.css';
import { SplashBackground } from 'assets/imagesList';
import { ROUTE_LOGIN, ROUTE_REGISTER_FORM, ROUTE_QUESTIONNAIRE } from 'routes';
//import { LogoGreen } from 'assets/imagesList';

export default function RegisterForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      history.push(ROUTE_QUESTIONNAIRE);
    }
  }, [user, loading]);

  return (
    <div className="login__container">
      {/*<img src={LogoGreen} alt="logo" />*/}

      <input
        type="text"
        className="login__textBox"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail adresa"
        style={{ marginTop: '45px' }}
      />
      <input
        type="password"
        className="login__textBox"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Lozinka"
      />
      <input
        type="password"
        className="login__textBox"
        value={repeatedPassword}
        onChange={(e) => setRepeatedPassword(e.target.value)}
        placeholder="Ponovljena lozinka"
      />
      <div
        style={{
          marginBottom: '20px',
          flexBasis: 'auto',
          alignItems: 'stretch',
          fontSize: '20px',
          fontWeight: 500,
        }}
      >
        <div
          style={{
            backgroundColor: 'rgb(88, 22, 105)',
            borderColor: 'rgb(88, 22, 105)',
            minHeight: '60px',
            borderRadius: '60px',
            borderWidth: '2px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            marginTop: '16px',
            fontSize: '16px',
          }}
          disabled={!password || password !== repeatedPassword}
          onClick={() => registerWithEmailAndPassword(email, password)}
        >
          <span style={{ marginLeft: '12px' }}>Registriraj se</span>
        </div>
      </div>
    </div>
  );
}
