import React from 'react';
import Grid from '@mui/material/Grid';
import 'assets/styles.css';
import { SplashBackground } from 'assets/imagesList';

const { REACT_APP_WEB_APP_URL } = process.env;

export default function PasswordlessSignin() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Grid
        item
        height="100vh"
        width="100%"
        style={{
          backgroundImage:
            'linear-gradient(rgb(88, 22, 105), rgb(3, 159, 188) 60%, rgb(169, 235, 222) 100%)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          textAlign: 'center',
          color: 'black',
          backgroundBlendMode: 'multiply',
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            top: '25%',
            position: 'fixed',
            left: '0px',
            right: '0px',
            bottom: '0px',
          }}
        >
          <img src={SplashBackground} width="100%" height="100%" />
        </div>
        <div
          style={{
            textAlign: 'center',
            fontSize: '25px',
            color: 'rgb(169, 235, 222)',
            fontWeight: 800,
            display: 'flex',
            flexDirection: 'column',
            flexBasis: 'auto',
            alignItems: 'stretch',
            position: 'relative',
            marginTop: '50px',
            paddingLeft: '20px',
            paddingRight: '20px',
            margin: '20px',
          }}
        >
          <div></div>
          <div>
            Na unesenu E-mail adresu poslan je link s poveznicom za prijavu.
            Klikom na poveznicu bit ćete automatski prijavljeni te preusmjereni
            u aplikaciju.
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
