import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_QUESTIONNAIRE, ROUTE_LOCK_SCREEN } from 'routes';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useActions } from 'overmind/index';

export default function PrivacyPolicy(props) {
  const history = useHistory();
  const actions = useActions();
  const [checkboxesState, setCheckboxesState] = useState(-1);
  const [enteredEmail, setEnteredEmail] = useState('');
  const [isOlderThanSixteen, setIsOlderThanSixteen] = useState(false);

  const handleChange = (e) => {
    setCheckboxesState(e.target.name);
    if (e.target.name == 0) {
      setIsOlderThanSixteen(true);
    } else {
      setIsOlderThanSixteen(false);
    }
  };

  const handleNext = () => {
    if (checkboxesState == 0 || enteredEmail) {
      return true;
    } else return false;
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      xs={12}
      style={{
        height: '100vh',
        width: '100vw',
        padding: '10px',
      }}
    >
      {/* Header and content */}
      <Grid item style={{ height: '50vx' /*border: '1px solid red'*/ }}>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid
            container
            direction="column"
            style={{
              maxWidth: '840px',
              /*border: '1px solid green',*/
            }}
          >
            <Grid item style={{ alignContent: 'center', maxHeight: '100vh' }}>
              <div
                style={{
                  fontSize: '14px',
                  fontWeight: 800,
                  color: '#581669',
                  height: '100vh',
                }}
              >
                <span padding="20px">
                  Pravila privatnosti mobilne aplikacije STRESS LOAD –
                  Longitudinalno istraživanje stresa adolescenata
                </span>
                <div
                  style={{
                    fontSize: '11px',
                    fontWeight: 500,
                    color: 'black',
                    height: '50vh',
                    overflow: 'scroll',
                    border: '1px solid #581669',
                    padding: '5px',
                    marginTop: '20px',
                  }}
                >
                  <p>Posljednje ažuriranje: 21.12.2021.</p>
                  <p>
                    Institut društvenih znanosti Ivo Pilar sa zadovoljstvom
                    pozdravlja Vaš posjet našoj aplikaciji i zahvaljuje Vam što
                    ste iskazali interes za naš znanstveni rad te njime povezana
                    istraživanja. Institut Ivo Pilar ozbiljno pristupa zaštiti
                    Vaših osobnih podataka u želji da se osjećate sigurno dok
                    koristite našu mobilnu aplikaciju.
                  </p>
                  <p>
                    <b>Opće informacije</b>
                  </p>
                  <p>
                    Ova Pravila privatnosti odnose se na korištenje mobilne
                    aplikacije “STRESS LOAD – Longitudinalno istraživanje stresa
                    adolescenata” (u daljnjem tekstu: "<b>Aplikacija</b>").
                    Korisnik Aplikacije u smislu naših Pravila privatnosti je
                    svaka fizička osoba (u daljnjem tekstu: "<b>Korisnik</b>")
                    koja dobrovoljno koristi uslugu Aplikacije.
                  </p>
                  <p>
                    Svaki korisnik Aplikacije u obvezi je upoznati se s
                    Pravilima privatnosti Aplikacije u cilju zaštite vlastitih
                    prava i interesa.
                  </p>
                  <p>
                    Registracijom i korištenjem Aplikacije, smatra se da su
                    Korisnici u svakom trenutku pročitali, razumjeli i
                    prihvatili ova Pravila privatnosti, te da su upoznati i
                    suglasni s Pravilima privatnosti, uključujući i podatke o
                    obradi podataka. Ukoliko se ne slažete u cijelosti s ovim
                    Pravilima privatnosti, upućujemo Vas da odmah prestanete s
                    korištenjem Aplikacije.
                  </p>
                  <p>
                    Aplikacija je u nadležnosti Ustanove INSTITUT DRUŠTVENIH
                    ZNANOSTI IVO PILAR, sa sjedištem u Zagrebu (Grad Zagreb),
                    Marulićev trg 19/I, osobni identifikacijski broj (OIB):
                    32840574937 (u daljnjem tekstu: "<b>Institut</b>").
                  </p>
                  <p>
                    <b>Značenje pojmova</b>
                  </p>
                  <p>
                    U smislu Pravila privatnosti pojedini pojmovi imaju sljedeće
                    značenje:
                  </p>
                  <i>
                    <p>
                      "<b>Institut</b>" označava Ustanovu INSTITUT DRUŠTVENIH
                      ZNANOSTI IVO PILAR, sa sjedištem u Zagrebu (Grad Zagreb),
                      Marulićev trg 19/I, osobni identifikacijski broj (OIB):
                      32840574937, ovdje u ulozi voditelja obrade, ujedno
                      subjekta odgovornog za obradu osobnih podataka u sklopu
                      Aplikacije;*
                    </p>
                    <p>
                      "<b>Aplikacija</b>" označava STRESS LOAD mobilnu
                      aplikaciju koja služi za provedbu longitudinalnog
                      istraživanja vezanog uz utvrđivanje odnosa stresa i
                      psihičkih problema adolescenata te provjeru uloge niza
                      potencijalnih medijatora i moderatora njihove povezanosti.
                      Longitudinalno panel istraživanje provest će se putem
                      mobilne aplikacije u četiri vala na uzorku učenika javnih
                      srednjih škola iz Zagreba i Zagrebačke županije (starosti
                      od 15 do 17 godina);*
                    </p>
                    <p>
                      "<b>Korisnik aplikacije/Korisnik</b>" označava osobu kojoj
                      je Institut omogućio korištenje Aplikacije i koja pristupa
                      Aplikaciji u skladu s ovim Pravilima privatnosti;*
                    </p>
                    <p>
                      "<b>Pravila privatnosti</b>" opisuju koje osobne podatke
                      prikupljamo, na koji način ih obrađujemo te u koje svrhe
                      ih upotrebljavamo, kao i prava Korisnika povezana s
                      osobnim podacima. Institut obrađuje osobne podatke
                      Korisnika u skladu s važećim propisima kojima je
                      regulirana zaštita osobnih podataka (Uredba (EU) 2016/679
                      Europskog parlamenta I Vijeća od 27. travnja 2016. o
                      zaštiti pojedinaca u vezi s obradom osobnih podataka i o
                      slobodnom kretanju takvih podataka te o stavljanju izvan
                      snage Direktive 95/46/EZ (u daljnjem tekstu: Opća uredba o
                      zaštiti podataka) te drugi primjenjivi nacionalni zakoni o
                      zaštiti osobnih podataka). Poštujemo Vašu privatnost i
                      obrađujemo osobne podatke pošteno i zakonito, isključivo
                      na temelju valjanih pravnih osnova ili u skladu s Vašim
                      privolama za obradu.*
                    </p>
                  </i>
                  <p>
                    <b>Osobni podaci koje prikupljamo</b>
                  </p>
                  <p>
                    Pravo pristupa i korištenja Aplikacije imaju isključivo
                    Korisnici Aplikacije. Korisnikom Aplikacije postaje se
                    registracijom odnosno prijavom u Aplikaciju. Prilikom
                    pokretanja Aplikacije prihvaćate Pravila privatnosti, a
                    nastavkom korištenja smatrat će se da je Korisnik aplikacije
                    pročitao, razumio i prihvatio ova Pravila privatnosti u
                    cijelosti.
                  </p>
                  <p>
                    Korištenje Aplikacije u potpunosti je dobrovoljno. Korisnici
                    samostalno odlučuju hoće li se registrirati, kojim podacima
                    će se registrirati te kako i kada će Aplikaciju koristiti.
                    Aplikacija ni u kojem trenutku ne prikuplja geolokacijske
                    podatke Korisnika, niti se podaci pohranjuju na samom
                    mobilnom uređaju bez odobrenja Korisnika.
                  </p>
                  <p>
                    Aplikacija može prikupljati sljedeće osobne podatke koje nam
                    učinite dostupnima:
                  </p>
                  <ul>
                    <li>
                      <b>Registracija.</b> Kada se registrirate u Aplikaciji sa
                      ciljem sudjelovanja u provođenju STRESS LOAD
                      longitudinalnog istraživanja, sprema se Vaša e-mail
                      adresa. Možete odlučiti dodati dodatne informacije u svoj
                      profil kao što je telefonski broj.
                    </li>
                    <li>
                      <b>Vaša komunikacija s nama.</b> Možemo prikupljat osobne
                      podatke, kao što su adrese e-pošte, telefonski brojevi ili
                      poštanske adrese, kada zatražite informacije o našem
                      znanstvenom radu, registrirate se za komunikaciju s nama,
                      zatražite korisničku ili tehničku podršku ili na drugi
                      način komunicirate s nama.
                    </li>
                    <li>
                      <b>Posebne kategorije osobnih podataka.</b> Možemo
                      prikupljati podatke koji se odnose na Vaše zdravlje, u
                      prvom redu psihološko stanje i životne navike, ukoliko bi
                      iste kao Korisnik dobrovoljno učinili dostupnima kroz
                      Aplikaciju, sukladno uvjetima i svrsi obrade određenima
                      ovim Pravilima privatnosti.
                    </li>
                    <li>
                      <b>Istraživanja.</b> Možemo Vas kontaktirati sa
                      prijedlogom sudjelovanja u našim istraživanjima koja bi
                      mogla uslijediti u nastavku.
                    </li>
                  </ul>
                  <p>Možemo automatski prikupljati sljedeće osobne podatke:</p>
                  <ul>
                    <li>
                      <b>Automatsko prikupljanje podataka.</b> Određene podatke
                      možemo prikupljati automatski kada koristite našu
                      Aplikaciju, kao što su Vaša adresa internetskog protokola
                      (IP) ili korisničke postavke. Također, možemo automatski
                      prikupljati podatke o Vašoj upotrebi Aplikacije, kao što
                      je trajanje ili učestalost Vaših aktivnosti, informacije o
                      dijelovima aplikacije koje koristite ili sadržaj na koji
                      kliknete, i druge informacije o tome kako koristite
                      Aplikaciju.
                    </li>
                  </ul>
                  <p>
                    <b>Svrha korištenja osobnih podataka</b>
                  </p>
                  <p>
                    Aplikacija će koristiti osobne podatke isključivo u svrhu
                    zbog koje su isti prikupljeni ili za korištenje
                    identificirano kao povezano s tom svrhom.
                  </p>
                  <p>
                    Svrha prikupljanja osobnih podataka određena je kao
                    provođenje znanstveno-istraživačkog projekta pod nazivom
                    STRESS LOAD - Longitudinalno istraživanje stresa
                    adolescenata. Cilj projekta je istražiti odnos stresa i
                    psihičkih problema adolescenata, te provjeriti ulogu niza
                    potencijalnih medijatora i moderatora njihove povezanosti.
                  </p>
                  <p>
                    Obrada koja se provodi nema diskriminacijski učinak na
                    pojedince na temelju rasnog ili etničkog porijekla,
                    političkog mišljenja, vjere ili uvjerenja, članstva u
                    sindikatu, genetskog ili zdravstvenog stanja ili spolne
                    orijentacije.
                  </p>
                  <p>
                    <b>Sigurnost osobnih podataka</b>
                  </p>
                  <p>
                    Poduzimamo korake kako bismo osigurali da se s Vašim
                    podacima postupa na siguran način, u skladu s ovim Pravilima
                    privatnosti. Nažalost, niti jedan sustav nije 100% siguran
                    te stoga ne možemo osigurati niti jamčiti stopostotnu
                    sigurnost osobnih podataka. Poduzeli smo odgovarajuće
                    sigurnosne mjere prihvaćene relevantnim međunarodnim
                    standardima i/ili najboljim organizacijskim i tehničkim
                    praksama kako bi osobni podaci Korisnika ostali zaštićeni u
                    najvećoj mogućoj mjeri.
                  </p>
                  <p>
                    Aplikacija ne prenosi niti dijeli osobne podatke Korisnika s
                    bilo kojim trećim stranama ili lokacijama. Korisnik bi
                    trebao Aplikaciji pristupiti samo u sigurnom okruženju.
                  </p>
                  <p>
                    <b>Privatnost djece</b>
                  </p>
                  <p>
                    Prepoznajemo potrebu za pružanjem daljnje zaštite
                    privatnosti u vezi s osobnim podacima koje kroz Aplikaciju
                    možemo prikupiti od djece. Kada postoji mogućnost
                    prikupljanja osobnih podataka od osoba mlađih od 16 godina,
                    poduzimamo dodatne korake za zaštitu privatnosti,
                    uključujući:
                  </p>
                  <ul>
                    <li>
                      Prepoznajemo potrebu za pružanjem daljnje zaštite
                      privatnosti u vezi s osobnim podacima koje kroz Aplikaciju
                      možemo prikupiti od djece. Kada postoji mogućnost
                      prikupljanja osobnih podataka od osoba mlađih od 16
                      godina, poduzimamo dodatne korake za zaštitu privatnosti,
                      uključujući:
                    </li>
                    <li>
                      U skladu s primjenjivim zakonom i našim praksama,
                      dobivanje pristanka roditelja/skrbnika za prikupljanje
                      osobnih podataka njihove djece ili za slanje informacija o
                      STRESS LOAD znanstvenom istraživanju;
                    </li>
                    <li>
                      Ograničavanje prikupljanja osobnih podataka djece samo na
                      razinu razumno potrebnu za sudjelovanje u STRESS LOAD
                      znanstvenom istraživanju;{' '}
                    </li>
                    <li>
                      Omogućavanje pristupa roditeljima/skrbnika ili mogućnost
                      traženja pristupa osobnim podacima koje smo prikupili od
                      njihove djece i mogućnost zahtjeva da se osobni podaci
                      izmijene ili izbrišu.
                    </li>
                  </ul>
                  <p>
                    <b>Koliko dugo se čuvaju podaci</b>
                  </p>
                  <p>
                    Aplikacija čuva osobne podatke onoliko dugo koliko Korisnik
                    koristi aplikaciju odnosno onoliko dugo koliko traje
                    istraživanje te obrada rezultata istog.
                  </p>
                  <p>
                    <b>Koja su prava Korisnika u pogledu obrade podataka</b>
                  </p>
                  <p>
                    Pridržavamo se Opće uredbe o zaštiti podataka i drugih
                    primjenjivih nacionalnih zakona o zaštiti osobnih podataka
                    te u skladu s njima omogućujemo Korisniku ostvarivanje
                    sljedećih prava:
                  </p>
                  <p>
                    Pravo na pristup osobnim podacima. Korisnik može dobiti
                    potvrdu obrađuju li se njegovi osobni podaci te ukoliko se
                    obrađuju, pristup tim podacima i sljedeće informacije:
                    informacije o svrsi obrade, kategorijama osobnih podataka o
                    kojima je riječ, o predviđenom razdoblju u kojem će osobni
                    podaci biti pohranjeni i postojanju prava ispitanika u
                    pogledu obrade osobnih podataka.
                  </p>
                  <p>
                    Pravo na ispravak osobnih podataka. Ukoliko su osobni podaci
                    Korisnika koji se obrađuju nepotpuni ili netočni, u bilo
                    kojem trenutku Korisnik može zatražiti Institut da iste
                    ispravi ili dopuni i to davanjem dodatne izjave. Napominje
                    se kako je Korisnik odgovoran za davanje ispravnih podataka,
                    a uz to ima obvezu informirati o relevantnim promjenama
                    njegovih osobnih podataka.
                  </p>
                  <p>
                    Pravo na brisanje osobnih podataka. Ako su podaci nezakonito
                    obrađivani ili ako takva obrada predstavlja nerazmjerno
                    zadiranje u zaštićene interese Korisnika, Korisnik ima pravo
                    u svakom trenutku zatražiti brisanje ustupljenih osobnih
                    podataka.
                  </p>
                  <p>
                    Pravo na prigovor. Korisnik ima pravo uložiti prigovor na
                    obradu njegovih osobnih podataka ako takva obrada nije nužna
                    radi provođenja znanstvenog istraživanja u skladu sa člankom
                    89. stavkom 1. Opće uredbe o zaštiti podataka, odnosno
                    ukoliko ista nije razmjerna cilju koji se nastoji postići.
                  </p>
                  <p>
                    Pravo na pritužbu nadzornom tijelu. Ako Korisnik smatra da
                    se prilikom obrade njegovih osobnih podataka nije postupalo
                    u skladu sa zakonskim obvezama i da je povrijeđeno njegovo
                    pravo na zaštitu osobnih podataka, Korisnik može podnijeti
                    prigovor Agenciji za zaštitu osobnih podataka, na adresi
                    Selska cesta 136, Zagreb, odnosno na elektroničku adresu{' '}
                    <a href="mailto: azop@azop.hr">azop@azop.hr</a>.
                  </p>
                  <p>
                    Pravo na obavješćivanje o povredi osobnih podataka. U
                    slučaju da i pored svih poduzetih mjera dođe do povrede
                    osobnih podataka Korisnika, o svakoj takvoj povredi Korisnik
                    će biti obaviješten bez nepotrebnog odgađanja i to slanjem
                    obavijesti u pisanom obliku.
                  </p>
                  <p>
                    U navedenoj obavijesti će se opisati priroda povrede osobnih
                    podataka, navesti ime i prezime osobe od koje se mogu dobiti
                    dodatne informacije o povredi, opis vjerojatne posljedice
                    povrede osobnih podataka i opis mjera koje su poduzete za
                    rješavanje problema povrede osobnih podataka uključujući i
                    mjere umanjivanja štetnih posljedica. Navedena obavijest će
                    biti sastavljena uporabom jasnog i jednostavnog jezika.
                  </p>
                  <p>
                    <b>Način ostvarivanja prava</b>
                  </p>
                  <p>
                    Ako Korisnik želi ostvariti neko od svojih prethodno
                    navedenih prava, može se obratiti pravnoj službi Instituta
                    koristeći adresu e-pošte navedenu u Pravilima privatnosti.
                  </p>
                  <p>
                    Prije davanja bilo kakvih podatka poduzet će se potrebne
                    mjere kako bi se potvrdio identitet Korisnika, a u slučaju
                    dvojbe mogu se od Korisnika zatražiti dodatne informacije.
                  </p>
                  <p>
                    Na zahtjev Korisnika odgovorit će se unutar mjesec dana od
                    zaprimanja istoga, a rok se, ovisno o složenosti zahtjeva,
                    može produljiti za dodatna dva mjeseca.
                  </p>
                  <p>
                    U slučaju zahtjeva za složenijom obradom veće količine
                    podataka zadržava se pravo naplate razumne naknade na
                    temelju administrativnih troškova nastalih pružanjem takvih
                    informacija.
                  </p>
                  <p>
                    <b>Intelektualno vlasništvo</b>
                  </p>
                  <p>
                    Materijali (multimedijski sadržaji, podaci, grafička djela,
                    jezična djela, crteži, skice...) dostupni i korišteni u
                    Aplikaciji su autorska djela i predmet zaštite autorskog
                    prava. Najstrože je zabranjeno bez odobrenja Instituta u
                    pisanom obliku reproduciranje (u jednom ili više primjeraka,
                    u cijelosti ili u dijelovima, izravno ili neizravno,
                    privremeno ili trajno, bilo kojim sredstvom i u bilo kojem
                    obliku), distribucija (prodaja, iznajmljivanje,
                    ustupanje...), priopćavanje ili prerada materijala koji su
                    korišteni u Aplikaciji.
                  </p>
                  <p>
                    <b>Odgovornost i isključenje odgovornosti</b>
                  </p>
                  <p>
                    Institut ne odgovara Korisniku Aplikacije za
                    nefunkcionalnost Aplikacije i ne jamči da materijali
                    sadržani u Aplikaciji u svakom trenutku u potpunosti točni,
                    precizni i pouzdani. Korisnik Aplikaciju koristi na vlastitu
                    odgovornost te na isto pristaje registracijom odnosno
                    prihvaćanjem Pravila privatnosti.
                  </p>
                  <p>Korištenjem Aplikacije Korisnik izjavljuje i jamči da:</p>
                  <ol>
                    <li>pristaje na poštovanje ovih Pravila privatnosti;</li>
                    <li>
                      neće koristiti Aplikaciju ni u kakve ilegalne ili
                      neovlaštene svrhe;
                    </li>
                    <li>
                      upotrebom Aplikacije neće prekršiti bilo kakav primjenjivi
                      zakon ili propis.
                    </li>
                  </ol>
                  <p>
                    Aplikacija se ne smije koristiti za bilo kakve poduhvate,
                    osim onih koji su izričito odobreni od strane Instituta.
                  </p>
                  <p>Kao Korisnik Aplikacije, isti prihvaća da neće:</p>

                  <ol>
                    <li>vršiti bilo kakvu neovlaštenu upotrebu Aplikacije;</li>
                    <li>
                      sudjelovati u neovlaštenom povezivanju na Aplikaciju;
                    </li>
                    <li>
                      dešifrirati, rastavljati ili obrađivati bilo koji softver
                      koji na bilo koji način čini dio Aplikacije;
                    </li>
                    <li>
                      koristiti Aplikaciju na način koji nije u skladu sa
                      primjenjivim zakonima ili drugim propisima.
                    </li>
                  </ol>

                  <p>
                    <b>Kontaktirajte nas</b>
                  </p>
                  <p>
                    Ukoliko imate bilo kakvih pitanja o našim praksama
                    privatnosti ili ovim Pravilima privatnosti, ili želite
                    ostvariti svoja prava kako je detaljno ovdje navedeno,
                    kontaktirajte nas putem adrese e-pošte: stress.load@pilar.hr
                    .
                  </p>
                  <p>Ova Pravila privatnosti primjenjuju se od: 21.12.2021.</p>
                </div>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={checkboxesState == 0}
                        onChange={(e) => handleChange(e)}
                        name={0}
                      />
                    }
                    label={
                      <span style={{ fontSize: '11px' }}>
                        Imam najmanje 16 godina te pristajem na primjenu Pravila
                        privatnosti
                      </span>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={checkboxesState == 1}
                        onChange={(e) => handleChange(e)}
                        name={1}
                      />
                    }
                    label={
                      <span style={{ fontSize: '11px' }}>
                        Imam manje od 16 godina
                      </span>
                    }
                  />
                </FormGroup>
                <p></p>
                {checkboxesState == 1 && (
                  <>
                    <TextField
                      id="outlined-basic"
                      label="E-mail roditelja"
                      variant="outlined"
                      size="small"
                      onChange={(event) => setEnteredEmail(event.target.value)}
                      value={enteredEmail}
                    />

                    <p
                      style={{
                        fontSize: '11px',
                        fontWeight: 500,
                        color: 'black',
                      }}
                    >
                      E-mail adresa roditelja/skrbnika radi pribavljanja privole
                      za prikupljanje osobnih podataka djece u svrhu provođenja
                      znanstvenog istraživanja
                    </p>
                  </>
                )}

                {/* Footer */}
                {handleNext() && (
                  <div
                    onClick={() => {
                      //props.onNext && props.onNext();

                      actions.savePrivacyPolicy({
                        older_than_sixteen: isOlderThanSixteen,
                        parent_email: enteredEmail,
                      });
                      if (isOlderThanSixteen) {
                        history.push(ROUTE_QUESTIONNAIRE);
                      } else {
                        history.push(ROUTE_LOCK_SCREEN);
                      }
                    }}
                    style={{
                      backgroundColor: 'rgb(88, 22, 105)',
                      borderColor: 'rgb(88, 22, 105)',
                      height: '44px',
                      width: '100%',
                      borderRadius: '22px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                  >
                    <span style={{ marginLeft: '12px' }}>Dalje</span>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
