import firebase from 'firebase';
import { initializeApp } from 'firebase/app';
import { axiosGET, axiosPOST, axiosPUT } from 'services/APIClient';
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithRedirect,
  GoogleAuthProvider,
  sendSignInLinkToEmail,
} from 'firebase/auth';

import { ROUTE_PASWORDLESS_SIGNIN } from 'routes';

// TODO: move to .env files (create one for DEVELOPMENT and one for PRODUCTION)
const firebaseConfig = {
  apiKey: 'AIzaSyCUM2N-A8DIETIl6LpFqiekY97uH4jvCFY',
  authDomain: 'ivopili.firebaseapp.com',
  projectId: 'ivopili',
  storageBucket: 'ivopili.appspot.com',
  messagingSenderId: '634543140775',
  appId: '1:634543140775:web:ae138c9d5c05566ce39082',
};

const SURVEY_ENDPOINT = 'survey/';
const ANSWER_ENDPOINT = 'answer/';
const WHO_AM_I_ENDPOINT = 'whoami/';
const ADMIN_SURVEYS_LIST = 'admin-survey/';
const FEEDBACK_ENDPOINT = 'feedback/';
const PHONE_NUMBER_ENDPOINT = 'phone-number/';
const PRIVACY_POLICY_ENDPOINT = 'privacy-policy/';
const PARENT_CONSENT_ENDPOINT = 'parent_consent/';
const EXPORT_CSV_ENDPOINT = 'export-csv/';
const EXPORT_NUMBERS_CSV_ENDPOINT = 'export-numbers-csv/';
const CHECK_TASK_STATUS_ENDPOINT = 'check-task-status/';
const RETURN_CSV_ENDPOINT = 'return-csv/';
const USER_PROFILE_ENDPOINT = 'user-profile/';
const FINISHED_CYCLE_ENDPOINT = 'finished_cycle/';
const SEND_REMINDERS_ENDPOINT = 'send_reminders/';
const SEND_REMINDERS_TO_NON_LOGGED_USERS_ENDPOINT =
  'send_reminders_to_non_logged_users/';
const EXPORT_USERS_CSV_ENDPOINT = 'export-users-csv/';

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

const { REACT_APP_WEB_APP_URL } = process.env;

export const signInWithGoogle = async () => {
  try {
    const res = await signInWithRedirect(auth, googleProvider);
    const user = res.user;
    // token = user.accessToken
    // photo = user.photoURLs
    // email: user.email
    // displayName: user.displayName
    // uid = user.uid
    // for more check https://firebase.google.com/docs/reference/js/auth.userinfo and https://firebase.google.com/docs/reference/js/auth.user

    /*const usersRef = collection(db, 'users');
    const q = query(usersRef, where('uid', '==', user.uid));
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot);*/
    const userRef = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      const userToSave = {
        uid: user.uid,
        authProvider: 'google',
        email: user.email,
      };
      await setDoc(doc(db, 'users', user.uid), userToSave);
    } else {
      //console.log(userSnap.data());
      /*userSnap.forEach((doc) => {
        console.log(doc.id, ' => ', doc.data());
      });*/
    }
  } catch (err) {
    console.error(err);
  }
};

export const getUserDetails = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userSnap = await getDoc(userRef);
  if (userSnap.exists) {
    return userSnap.data();
  } else {
    return null;
  }
};

export const saveUserProfile = async (userId, profile) => {
  const userRef = doc(db, 'users', userId);
  setDoc(userRef, { profile: profile }, { merge: true });
};

export const signInWithEmail = async (email) => {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: REACT_APP_WEB_APP_URL,
    // This must be true.
    handleCodeInApp: true,
  };
  const auth = getAuth();
  sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
      window.location = ROUTE_PASWORDLESS_SIGNIN;
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);
      console.log(errorMessage);
      // ...
    });
};

export const registerWithEmailAndPassword = async (email, password) => {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: REACT_APP_WEB_APP_URL,
    // This must be true.
    handleCodeInApp: true,
  };
  const auth = getAuth();
  sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
    });
};

export const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
    alert('Password reset link sent!');
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const logout = async () => {
  const authentication = getAuth();
  await authentication.signOut();
  window.location = '/';
};

export const api = {
  getSurvey: async (cycleId) => {
    return await axiosGET(
      cycleId ? `${SURVEY_ENDPOINT}${cycleId}/` : SURVEY_ENDPOINT
    );
  },
  postAnswer: async (value, text, custom_question, question_id) => {
    return await axiosPOST(ANSWER_ENDPOINT, {
      value: value,
      text: text,
      custom_question: custom_question,
      question_id: question_id,
    });
  },
  getWhoAmI: async () => {
    return await axiosGET(WHO_AM_I_ENDPOINT);
  },
  getAdminSurveys: async () => {
    return await axiosGET(ADMIN_SURVEYS_LIST);
  },
  postPhoneNumber: async (value) => {
    return await axiosPOST(PHONE_NUMBER_ENDPOINT, {
      value: value,
    });
  },
  getFeedback: async () => {
    return await axiosGET(FEEDBACK_ENDPOINT);
  },
  getUserProfile: async () => {
    return await axiosGET(USER_PROFILE_ENDPOINT);
  },
  postPrivacyPolicy: async (older_than_sixteen, parent_email) => {
    return await axiosPOST(PRIVACY_POLICY_ENDPOINT, {
      older_than_sixteen: older_than_sixteen,
      parent_email: parent_email,
    });
  },
  postParentConsent: async (consent_uuid, parent_consent_received) => {
    return await axiosPOST(PARENT_CONSENT_ENDPOINT, {
      consent_uuid: consent_uuid,
      parent_consent_received: parent_consent_received,
    });
  },
  exportCSVFile: async (cycleId) => {
    return await axiosGET(`${EXPORT_CSV_ENDPOINT}${cycleId}/`);
  },
  exportNumbersCSVFile: async () => {
    return await axiosGET(`${EXPORT_NUMBERS_CSV_ENDPOINT}`);
  },
  exportUsersCSVFile: async () => {
    return await axiosGET(`${EXPORT_USERS_CSV_ENDPOINT}`);
  },
  getTaskStatus: async (taskId) => {
    return await axiosGET(`${CHECK_TASK_STATUS_ENDPOINT}${taskId}/`);
  },
  getCSVFile: async (reqData) => {
    return await axiosGET(
      `${RETURN_CSV_ENDPOINT}${reqData.id}/?number=${reqData.number}`
    );
  },

  getUsersCSVFile: async (reqData) => {
    return await axiosGET(
      `${RETURN_CSV_ENDPOINT}${reqData.id}/?users=${reqData.users}`
    );
  },
  postFinishedCycle: async (cycle_id) => {
    return await axiosPOST(FINISHED_CYCLE_ENDPOINT, {
      cycle_id: cycle_id,
    });
  },
  sendReminders: async () => {
    return await axiosGET(SEND_REMINDERS_ENDPOINT);
  },
  sendRemindersToNonLoggedUsers: async () => {
    return await axiosGET(SEND_REMINDERS_TO_NON_LOGGED_USERS_ENDPOINT);
  },
};

export default firebase;
