import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'firebase';
import { useActions } from 'overmind/index';
import { useOvermindState } from 'overmind/index';
import { ROUTE_SPLASH } from 'routes';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

const { REACT_APP_WEB_APP_URL } = process.env;
const web_app_url = REACT_APP_WEB_APP_URL;

export default function ParentConsent(props) {
  const [user, loading] = useAuthState(auth);
  const actions = useActions();
  const history = useHistory();

  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      aligntItems="center"
      xs={12}
      style={{
        height: '100vh',
        width: '100vw',
        padding: '10px',
      }}
    >
      <Grid
        container
        direction="column"
        style={{
          maxWidth: '840px',
          /*border: '1px solid green',*/
        }}
      >
        <Grid item style={{ alignContent: 'center', maxHeight: '100vh' }}>
          <div
            style={{
              fontSize: '18px',
              fontWeight: 800,
              color: '#581669',
              height: '100vh',
              justifyItems: 'center',
              textAlign: 'center',
              marginTop: '30px',
            }}
          >
            <span padding="20px">DAVANJE PRIVOLE RODITELJA / SKRBNIKA</span>
            <div
              style={{
                fontSize: '14px',
                fontWeight: 500,
                color: 'black',
                height: '50vh',
                padding: '5px',
                marginTop: '20px',
              }}
            >
              <p>
                Davanjem privole pristajete na sudjelovanje Vašeg djeteta u
                znanstvenom istraživanju{' '}
                <i>
                  Stress load: Longitudinalno istraživanje stresa adolescenata
                </i>{' '}
                te na pripadajuća{' '}
                <Link
                  component="button"
                  variant="body2"
                  onClick={() =>
                    window.open(`${web_app_url}/polica-privatnosti`)
                  }
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    paddingLeft: '4px',
                  }}
                >
                  <b>Pravila privatnosti</b>
                </Link>
                .
              </p>
              <p>
                Tijekom provođenja znanstvenog istraživanja, moguće je
                prikupljanje osobnih podataka sudionika, poput datuma rođenja,
                e-mail adrese, broja mobitela ili informacija o njihovom
                psihološkom stanju. Rezultati istraživanja obrađivat će se
                isključivo skupno, bez vidljivih poveznica s individualnim
                sudionicima. Osobni podaci djeteta neće se dijeliti sa trećim
                stranama.
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                <div
                  onClick={() => {
                    var url = window.location.href;
                    var search_index = url.indexOf('id=');
                    if (search_index !== -1) {
                      actions.saveParentConsent({
                        consent_uuid: url.substring(search_index + 3),
                        parent_consent_received: true,
                      });

                      alert('Zahvaljujemo na danoj suglasnosti!');
                    }
                  }}
                  style={{
                    backgroundColor: 'rgb(88, 22, 105)',
                    borderColor: 'rgb(88, 22, 105)',
                    height: '44px',
                    width: '150px',
                    borderRadius: '22px',
                    justifyContent: 'center',
                    color: 'white',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span>SUGLASAN SAM</span>
                </div>
              </div>

              <p>
                Davanje privole je dobrovoljno te ju roditelji/skrbnici mogu u
                svakom trenutku povući te tražiti prestanak daljnje obrade.
              </p>
              <p>
                <i>
                  Postupak davanja privole roditelja/skrbnika usklađen je sa
                  člankom 6. stavak 1. točka a), člankom 7. te člankom 9. stavak
                  2. točka a) Uredbe (EU) 2016/679 Europskog parlamenta i Vijeća
                  od 27. travnja 2016. o zaštiti pojedinaca u vezi s obradom
                  osobnih podataka i o slobodnom kretanju takvih podataka te o
                  stavljanju izvan snage Direktive 95/46/EZ (Opća uredbe o
                  zaštiti podataka, Službeni list Europske unije, L119/1)
                </i>
              </p>
              <p>
                <i>
                  Izrazi koji se koriste u ovoj privoli upotrijebljeni su
                  neutralno i odnose se na osobe oba spola.
                </i>
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
