import React, { useEffect } from 'react';
import { auth, logout } from 'firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

export default function SignoutPage() {
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!loading) {
      if (user) {
        logout();
      } else {
        window.location = '/';
      }
    }
  }, [user, loading]);

  return <></>;
}
