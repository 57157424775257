import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';

import { useOvermindState, useActions } from 'overmind/index';

export default function PhoneNumber(props) {
  const actions = useActions();
  const overmindState = useOvermindState();
  const { showBack, user } = props;
  const [selectedValue, setSelectedValue] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const renderPhoneNumber = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          direction="row"
          style={{
            maxWidth: '300px',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ marginBottom: '16px' }}>
            {(
              <>
                <input
                  type="text"
                  className="onboarding__textBox"
                  value={selectedValue}
                  onChange={(e) => {
                    setSelectedValue(e.target.value.replace(/[^\d]/, ''));
                  }}
                  placeholder=""
                  style={{
                    border: '2px solid ' + props.options.color,
                    backgroundColor: props.options.background_color,
                    color: props.options.color,
                  }}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      style={{
        height: '100vh',
        width: '100vw',
        padding: '10px',
        backgroundColor: props.options.background_color,
      }}
    >
      {/* Header and content */}
      <Grid item style={{ height: '100px' /*border: '1px solid red'*/ }}>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid
            container
            direction="column"
            style={{
              maxWidth: '840px',
              /*border: '1px solid green',*/
            }}
          >
            <Grid item style={{ height: '64px' }}>
              {showBack && (
                <div
                  onClick={() => props.onBack && props.onBack()}
                  style={{
                    height: '44px',
                    width: '44px',
                    border: '2px solid ' + props.options.color,
                    borderRadius: '22px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ArrowBackIcon
                    style={{ color: props.options.color }}
                    sx={{ fontSize: 32 }}
                  />
                </div>
              )}
            </Grid>
            <Grid item>
              <div style={{ paddingTop: '80px' }}>
                <span
                  style={{
                    fontSize: '34px',
                    fontWeight: 800,
                    color: props.options.color,
                  }}
                >
                  {'Ukoliko želite podsjetnik na buduće cikluse istraživanja unesite svoj broj mobitela: '}
                </span>
              </div>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Grid
                container
                direction="row"
                style={{
                  maxWidth: '300px',
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item >
                  {renderPhoneNumber()}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Grid>

      {/* Footer */}
      <Grid
        item
        style={{
          marginBottom: '20px',
          /*border: '1px solid red',*/
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          <Grid
            container
            direction="column"
            style={{
              maxWidth: '540px',
              /*border: '1px solid green',*/
            }}
          >
            <Grid item style={{ height: '110px' }}>
              {props.showNext && (
                <>
                  <div
                    onClick={() => {
                      props.onNext && props.onNext();
                      actions.savePhoneNumber(selectedValue);
                      setSelectedValue(undefined);
                    }}
                    style={{
                      height: '44px',
                      width: '100%',
                      border: '2px solid ' + props.options.color,
                      borderRadius: '22px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: props.options.color,
                      color: 'white',
                      cursor: 'pointer',
                    }}
                  >
                    <span>Dalje</span>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
