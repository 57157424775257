const userProfile = {
  data: undefined,
  error: undefined,
  loadingOrMutating: false,
};

export const state = {
  user: undefined,
  profile: userProfile,
  currentOnboardingIndex: 1,
  activeSurvey: {
    fetching: false,
    data: undefined,
  },
  feedback: {
    fetching: false,
    data: undefined,
  },
  adminSurvey: {
    fetching: false,
    data: undefined,
  },
  phoneNumber: {
    fetching: false,
    data: {
      taskId: undefined,
      fileId: undefined,
    },
  },
  users: {
    fetching: false,
    data: {
      taskId: undefined,
      fileId: undefined,
    },
  },
  isUserAdmin: false,
  finishedCycles: [],
};
