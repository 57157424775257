import React, { useEffect } from 'react';
import { ROUTE_QUESTIONNAIRE } from 'routes';
import { useHistory } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'firebase';
import { useActions } from 'overmind/index';
import { useOvermindState } from 'overmind/index';
import Loader from 'components/atoms/Loader/Loader';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CircularProgress from '@mui/material/CircularProgress';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

export default function AdminQuestionnaireList() {
  const history = useHistory();
  const overmindState = useOvermindState();
  const actions = useActions();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (!loading && user) {
      actions.fetchAdminSurveysList();
    }
  }, [user, loading]);

  useEffect(() => {
    const interval = setInterval(() => {
      overmindState.adminSurvey &&
        overmindState.adminSurvey.data.map((survey, ix) => {
          if (survey.loading === true) {
            actions.fetchTaskStatus(survey);
          }
        });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const intervalNumbers = setInterval(() => {
      overmindState.phoneNumber &&
        overmindState.phoneNumber.data &&
        overmindState.phoneNumber.fetching === true &&
        actions.fetchTaskStatusNumber(overmindState.phoneNumber.data);
    }, 10000);

    return () => clearInterval(intervalNumbers);
  }, []);

  useEffect(() => {
    const intervalNumbers = setInterval(() => {
      overmindState.users &&
        overmindState.users.data &&
        overmindState.users.fetching === true &&
        actions.fetchTaskStatusUsers(overmindState.users.data);
    }, 10000);

    return () => clearInterval(intervalNumbers);
  }, []);

  const startDownload = (reqData) => {
    actions.exportCSVFile(reqData.survey.id);
    actions.setLoadingSurvey(reqData);
  };

  const startDownloadNumbers = (reqData) => {
    actions.exportNumbersCSVFile();
    actions.setLoadingNumbers(reqData);
  };

  const startDownloadUsers = (reqData) => {
    actions.exportUsersCSVFile();
    actions.setLoadingUsers(reqData);
  };

  const sendReminders = () => {
    actions.sendReminders();
  };

  const sendRemindersToNonLoggedUsers = () => {
    actions.sendRemindersToNonLoggedUsers();
  };

  return (
    <>
      {overmindState.adminSurvey.fetching ? (
        <Loader />
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Grid container direction="column">
            <h1>Admin panel</h1>
            <h2>Popis svih ciklusa: </h2>
            {overmindState.adminSurvey.data &&
              overmindState.adminSurvey.data.map((survey, ix) => {
                return (
                  <Grid item key={ix} style={{ marginBottom: '16px' }}>
                    <a href={`${ROUTE_QUESTIONNAIRE}?cycleId=${survey.id}`}>
                      {survey.name}{' '}
                    </a>
                    {survey.loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <IconButton
                        aria-label="download"
                        onClick={() =>
                          startDownload({ survey: survey, loading: true })
                        }
                      >
                        <FileDownloadIcon></FileDownloadIcon>
                      </IconButton>
                    )}
                    {!survey.loading && survey.fileId && (
                      <Button
                        variant="text"
                        onClick={() =>
                          actions.fetchCSVFile({
                            id: survey.fileId,
                            number: false,
                          })
                        }
                      >
                        Download export
                      </Button>
                    )}
                  </Grid>
                );
              })}
            <Grid
              container
              display="flex"
              justifyContent="start"
              alignItems="flex-start"
              direction="column"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <p>EXPORT PHONE NUMBERS</p>
                {overmindState.phoneNumber.fetching ? (
                  <CircularProgress size={24} />
                ) : (
                  <IconButton
                    aria-label="download"
                    onClick={() => startDownloadNumbers({ loading: true })}
                  >
                    <FileDownloadIcon></FileDownloadIcon>
                  </IconButton>
                )}
                {!overmindState.phoneNumber.fetching &&
                  overmindState.phoneNumber.data?.fileId && (
                    <Button
                      variant="text"
                      onClick={() =>
                        actions.fetchCSVFile({
                          id: overmindState.phoneNumber.data.fileId,
                          number: true,
                        })
                      }
                    >
                      Download export
                    </Button>
                  )}
              </Box>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="start"
              alignItems="flex-start"
              direction="column"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <p>EXPORT USERS</p>
                {overmindState.users.fetching ? (
                  <CircularProgress size={24} />
                ) : (
                  <IconButton
                    aria-label="download"
                    onClick={() => startDownloadUsers({ loading: true })}
                  >
                    <FileDownloadIcon></FileDownloadIcon>
                  </IconButton>
                )}
                {!overmindState.users.fetching &&
                  overmindState.users.data?.fileId && (
                    <Button
                      variant="text"
                      onClick={() =>
                        actions.fetchCSVFileUsers({
                          id: overmindState.users.data.fileId,
                          users: true,
                        })
                      }
                    >
                      Download export
                    </Button>
                  )}
              </Box>
            </Grid>
            <Grid
              container
              display="flex"
              justifyContent="start"
              alignItems="flex-start"
              direction="column"
            >
              <IconButton aria-label="download" onClick={() => sendReminders()}>
                <ForwardToInboxIcon></ForwardToInboxIcon>
                <a style={{ marginLeft: '20px', fontSize: '16px' }}>
                  POŠALJI E-MAIL PODSJETNIK SVIM KORISNICIMA
                </a>
              </IconButton>
              <IconButton
                aria-label="download"
                onClick={() => sendRemindersToNonLoggedUsers()}
              >
                <ForwardToInboxIcon></ForwardToInboxIcon>
                <a style={{ marginLeft: '20px', fontSize: '16px' }}>
                  POŠALJI E-MAIL PODSJETNIK SVIM KORISNICIMA KOJI SE NISU
                  LOGIRALI ZADNJIH MJESEC DANA
                </a>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
