import React, { useEffect } from 'react';
import { useActions } from 'overmind/index';
import { useOvermindState } from 'overmind/index';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Loader from 'components/atoms/Loader/Loader';
import { ROUTE_ADMIN_QUESTIONNAIRE } from 'routes';
import PrivacyPolicy from 'components/PrivacyPolicy';

export default function PrivacyPolicyPage() {
  const actions = useActions();
  const overmindState = useOvermindState();

  useEffect(() => {
    actions.fetchIsUserAdmin();
  }, []);

  return (
    <>
      {overmindState.activeSurvey.fetching ? (
        <Loader />
      ) : (
        <>
          {overmindState.isUserAdmin && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Grid container direction="column">
                <h2>Ulogirani ste s administratorskim računom</h2>
                <h3>
                  Na <a href={ROUTE_ADMIN_QUESTIONNAIRE}>idućem linku</a> možete
                  odabrati proizvoljan upitnik
                </h3>
              </Grid>
            </Box>
          )}
          <PrivacyPolicy></PrivacyPolicy>
        </>
      )}
    </>
  );
}
