import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const baseUrl = REACT_APP_API_URL;

// Instance containing config for all requests
// Unavailable from outside
const axiosInstance = axios.create({
  //TODO: this should be done only once after login and token fetch, include token, and keep instance somewhere
  baseURL: `${baseUrl}/`,
});

/**
 * Retrieves the user token from local storage
 */
const getToken = () => window.localStorage.getItem('access_token');

const setToken = (accessToken) => {
  // Saves user token to session storage
  window.localStorage.setItem('access_token', accessToken);
};

const removeToken = () => {
  // Removes user token to session storage
  window.localStorage.removeItem('access_token');
};

/**
 * Returns information about
 */
const getHeaders = () => {
  return {
    headers: {
      Authorization: `JWT ${getToken()}`,
      // getTimezoneOffset is calculated as UTC - current_timezone
      // so we have to multiply it with -1 to get real offset from UTC
      'Time-Zone-Offset': new Date().getTimezoneOffset() * -1,
    },
  };
};

// Unique method to handle API errors accross whole app
// Could be moved somewhere else and check if Error Boundaries can be implemented on top of this
const handleErrors = (error, preventRedirect) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.debug('ERROR STATUS: ', error.response.status);
    console.debug(error.response);
    if (error.response.status === 401) {
      if (preventRedirect !== true) {
        window.localStorage.removeItem('access_token');
        window.location = '/';
      } else {
        throw error;
      }
    } else {
      throw error;
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.debug('No response');
    console.debug('Error request: ', error.request);
    throw new Error(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.debug('Error message: ', error.message);
    throw new Error(error.message);
  }
  console.debug(error.config);
};

// Method for creating GET requests with implemented error handling
// Unavailable from outside
const axiosGET = async (path) => {
  let response = await axiosInstance
    .get(path, getToken() ? getHeaders() : undefined)
    .catch((error) => handleErrors(error, path === '/user/'));

  if (response) {
    return response.data;
  } else {
    return {};
  }
};

// Method for creating PUT requests with implemented error handling
// Unavailable from outside
const axiosPUT = async (path, params) => {
  let response = await axiosInstance
    .put(path, params, getToken() ? getHeaders() : undefined)
    .catch((error) => handleErrors(error, path === '/user/register/'));

  if (response) {
    return response.data;
  } else {
    return {};
  }
};

// Method for creating POST requests with implemented error handling
// Unavailable from outside
const axiosPOST = async (path, params) => {
  let response = await axiosInstance
    .post(path, params, getToken() ? getHeaders() : undefined)
    .catch((error) => handleErrors(error, path === '/user/register/'));

  if (response) {
    return response.data;
  } else {
    return {};
  }
};

export { axiosGET, axiosPUT, axiosPOST };
