import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import CircularProgress from '@mui/material/CircularProgress';

export default function MailSignInButton(props) {
  return (
    <button
      onClick={props.onClick}
      style={{
        backgroundColor: 'rgb(88, 22, 105)',
        borderColor: 'rgb(88, 22, 105)',
        minHeight: '60px',
        borderRadius: '60px',
        borderWidth: '2px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        marginTop: '16px',
        paddingLeft: '16px',
        paddingRight: '16px',
        width: '100%',
      }}
    >
      {!props.showSpinner ? (
        <MailOutlineIcon style={{ width: '48', height: '48' }} />
      ) : (
        <CircularProgress />
      )}
      <span style={{ marginLeft: '12px', fontSize: '12px' }}>{props.text}</span>
    </button>
  );
}
