import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useActions } from 'overmind/index';

import {
  ROUTE_SPLASH,
  ROUTE_LOGIN,
  ROUTE_REGISTER,
  ROUTE_QUESTIONNAIRE,
  ROUTE_ADMIN_QUESTIONNAIRE,
  ROUTE_SIGNOUT,
  ROUTE_PRIVACY_POLICY,
  ROUTE_PARENT_CONSENT,
  ROUTE_LOCK_SCREEN,
  ROUTE_FINAL_SCREEN,
  ROUTE_CYCLE_ALREADY_FINISHED,
  ROUTE_NO_ACTIVE_SURVEY,
  ROUTE_PASWORDLESS_SIGNIN,
} from 'routes';

import SplashPage from 'pages/SplashPage';
import LoginPage from 'pages/LoginPage';
import RegisterPage from 'pages/RegisterPage';
import QuestionnairePage from 'pages/QuestionnairePage';
import SignoutPage from 'pages/SignoutPage';
import AdminQuestionnaireList from 'pages/AdminQuestionnaireList';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import ParentConsentPage from 'pages/ParentConsentPage';
import LockScreen from 'components/LockScreen';
import FinalScreen from 'pages/FinalScreen';
import CycleAlreadyFinished from 'pages/CycleAlreadyFinished';
import NoActiveSurvey from 'pages/NoActiveSurveyScreen';
import PasswordlessSignin from 'pages/PasswordlessSignin';

export default function App() {
  return (
    <Switch>
      <Route exact path={ROUTE_SPLASH} component={SplashPage} />
      <Route exact path={ROUTE_LOGIN} component={LoginPage} />
      <Route exact path={ROUTE_REGISTER} component={RegisterPage} />
      <Route exact path={ROUTE_QUESTIONNAIRE} component={QuestionnairePage} />
      <Route
        exact
        path={ROUTE_ADMIN_QUESTIONNAIRE}
        component={AdminQuestionnaireList}
      />
      <Route exact path={ROUTE_SIGNOUT} component={SignoutPage} />
      <Route exact path={ROUTE_PRIVACY_POLICY} component={PrivacyPolicyPage} />
      <Route exact path={ROUTE_PARENT_CONSENT} component={ParentConsentPage} />
      <Route exact path={ROUTE_LOCK_SCREEN} component={LockScreen} />
      <Route exact path={ROUTE_FINAL_SCREEN} component={FinalScreen} />
      <Route
        exact
        path={ROUTE_CYCLE_ALREADY_FINISHED}
        component={CycleAlreadyFinished}
      />
      <Route exact path={ROUTE_NO_ACTIVE_SURVEY} component={NoActiveSurvey} />
      <Route
        exact
        path={ROUTE_PASWORDLESS_SIGNIN}
        component={PasswordlessSignin}
      />
    </Switch>
  );
}
