import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { auth, signInWithEmail } from 'firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import 'assets/styles.css';
//import { LogoGreen } from 'assets/imagesList';
import { useActions } from 'overmind/index';
import { SplashBackground } from 'assets/imagesList';
import { ROUTE_REGISTER, ROUTE_QUESTIONNAIRE } from 'routes';

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const actions = useActions();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      actions.setUser(user);
      history.push(ROUTE_QUESTIONNAIRE);
    }
  }, [user, loading]);

  return (
    <div className="login__container">
      {/*<img src={LogoGreen} alt="logo" />*/}

      <input
        type="text"
        className="login__textBox"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail adresa"
        style={{ marginTop: '45px' }}
      />

      <div
        style={{
          marginBottom: '20px',
          flexBasis: 'auto',
          alignItems: 'stretch',
          fontSize: '20px',
          fontWeight: 500,
        }}
      >
        <div
          style={{
            backgroundColor: 'rgb(88, 22, 105)',
            borderColor: 'rgb(88, 22, 105)',
            minHeight: '60px',
            borderRadius: '60px',
            borderWidth: '2px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            marginTop: '16px',
            fontSize: '16px',
          }}
          disabled={!email}
          onClick={() => signInWithEmail(email)}
        >
          <span style={{ marginLeft: '12px' }}>Prijava</span>
        </div>
      </div>
    </div>
  );
}
