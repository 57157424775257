export const incrementOnboardingIndex = ({ state }) => {
  state.currentOnboardingIndex += 1;
};

export const decrementOnboardingIndex = ({ state }) => {
  state.currentOnboardingIndex -= 1;
};

export const setToken = ({ state }, token) => {
  state.token = token;
};

export const setUser = ({ state }, user) => {
  state.user = user;
  state.profile.data = user.profile;
};

export const fetchUserDetails = async ({ effects, actions }, userId) => {
  effects
    .getUserDetails(userId)
    .then((response) => {
      actions.setUser(response);
    })
    .catch(() => {});
};

export const setSurveyLoadingData = async ({ state }, reqData) => {
  for (var adminSurvey of state.adminSurvey.data) {
    if (adminSurvey.id === reqData.cycleId) {
      adminSurvey.taskId = reqData.taskId;
      adminSurvey.fileId = reqData.fileId;
    }
  }
};

export const exportCSVFile = async ({ effects, actions }, cycleId) => {
  effects.api.exportCSVFile(cycleId).then((response) => {
    actions.setSurveyLoadingData({
      cycleId: cycleId,
      taskId: response.task_id,
      fileId: response.file_id,
    });
  });
};

export const setNumbersLoadingData = async ({ state }, reqData) => {
  state.phoneNumber.data.taskId = reqData.taskId;
  state.phoneNumber.data.fileId = reqData.fileId;
};

export const exportNumbersCSVFile = async ({ effects, actions }) => {
  effects.api.exportNumbersCSVFile().then((response) => {
    actions.setNumbersLoadingData({
      taskId: response.task_id,
      fileId: response.file_id,
    });
  });
};

export const setUsersLoadingData = async ({ state }, reqData) => {
  state.users.data.taskId = reqData.taskId;
  state.users.data.fileId = reqData.fileId;
};

export const exportUsersCSVFile = async ({ effects, actions }) => {
  effects.api.exportUsersCSVFile().then((response) => {
    actions.setUsersLoadingData({
      taskId: response.task_id,
      fileId: response.file_id,
    });
  });
};

export const fetchTaskStatus = async ({ effects, actions }, survey) => {
  effects.api
    .getTaskStatus(survey.taskId)
    .then((response) => {
      if (response.status === 'DONE')
        actions.setLoadingSurvey({ survey: survey, loading: false });
    })
    .catch(() => {});
};

export const fetchTaskStatusNumber = async ({ effects, actions }, number) => {
  effects.api
    .getTaskStatus(number.taskId)
    .then((response) => {
      if (response.status === 'DONE')
        actions.setLoadingNumbers({ loading: false });
    })
    .catch(() => {});
};

export const fetchTaskStatusUsers = async ({ effects, actions }, users) => {
  effects.api
    .getTaskStatus(users.taskId)
    .then((response) => {
      if (response.status === 'DONE')
        actions.setLoadingUsers({ loading: false });
    })
    .catch(() => {});
};

export const fetchCSVFileUsers = async ({ effects }, reqData) => {
  effects.api
    .getUsersCSVFile(reqData)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]), {
        type: 'text/csv',
      });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `users-exp-${reqData.id}.csv`);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(() => {});
};
export const fetchCSVFile = async ({ effects }, reqData) => {
  effects.api
    .getCSVFile(reqData)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]), {
        type: 'text/csv',
      });
      const link = document.createElement('a');
      link.href = url;
      if (reqData.number === false) {
        link.setAttribute('download', `answers-exp-${reqData.id}.csv`);
      } else {
        link.setAttribute('download', `numbers-exp-${reqData.id}.csv`);
      }
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch(() => {});
};

export const setActiveSurvey = ({ state }, survey) => {
  state.activeSurvey.data = survey;
  state.activeSurvey.fetching = false;
};

export const fetchIsUserAdmin = async ({ effects, state }) => {
  await effects.api.getWhoAmI().then((response) => {
    state.isUserAdmin = response['request.user'].is_staff;
  });
};

export const fetchUserProfile = async ({ effects, state }) => {
  await effects.api.getUserProfile().then((response) => {
    state.isUser16 = response.older_than_sixteen;
    state.isParentConsentReceived = response.parent_consent_received;
    state.finishedCycles = response.finished_cycle;
  });
};

export const setLoadingSurvey = async ({ state }, reqData) => {
  for (var adminSurvey of state.adminSurvey.data) {
    if (adminSurvey.id === reqData.survey.id) {
      adminSurvey.loading = reqData.loading;
    }
  }
};

export const setLoadingNumbers = async ({ state }, reqData) => {
  state.phoneNumber.fetching = reqData.loading;
};

export const setLoadingUsers = async ({ state }, reqData) => {
  state.users.fetching = reqData.loading;
};

export const fetchAdminSurveysList = async ({ state, effects }) => {
  state.adminSurvey.fetching = true;
  await effects.api.getAdminSurveys().then((response) => {
    for (var survey of response) {
      survey.loading = false;
      survey.fileId = null;
      survey.taskId = null;
    }
    state.adminSurvey.data = response;
    state.adminSurvey.fetching = false;
  });
};

export const fetchActiveSurvey = async (
  { state, effects, actions },
  cycleId
) => {
  state.activeSurvey.fetching = true;
  effects.api
    .getSurvey(cycleId)
    .then((response) => {
      actions.setActiveSurvey(response);
      return response;
    })

    .catch(() => {
      actions.setActiveSurvey(null);
    });
};

export const saveAnswer = async ({ effects }, reqData) => {
  effects.api.postAnswer(
    reqData.value,
    reqData.text,
    reqData.customQuestion,
    reqData.question_id
  );
};

export const savePhoneNumber = async ({ effects }, value) => {
  effects.api.postPhoneNumber(value);
};

export const savePrivacyPolicy = async ({ effects }, reqData) => {
  effects.api.postPrivacyPolicy(
    reqData.older_than_sixteen,
    reqData.parent_email
  );
};

export const saveParentConsent = async ({ effects }, reqData) => {
  effects.api.postParentConsent(
    reqData.consent_uuid,
    reqData.parent_consent_received
  );
};

export const setProfileState = ({ state }, error, loadingOrMutating) => {
  state.profile.error = error;
  state.profile.loadingOrMutating = loadingOrMutating;
};

export const saveFirstName = async ({ state, effects, actions }, firstName) => {
  actions.setProfileState(null, true);
  if (state.profile.data == null) {
    state.profile.data = {};
  }
  state.profile.data.firstName = firstName;
  effects
    .saveUserProfile(state.user.uid, state.profile.data)
    .then(() => {
      actions.incrementOnboardingIndex();
      actions.setProfileState(null, false);
    })
    .catch(() => {
      actions.setProfileState('error', false);
    });
};

export const saveGender = async ({ state, effects, actions }, gender) => {
  actions.setProfileState(null, true);
  if (state.profile.data == null) {
    state.profile.data = {};
  }
  state.profile.data.gender = gender;
  effects
    .saveUserProfile(state.user.uid, state.profile.data)
    .then(() => {
      actions.incrementOnboardingIndex();
      actions.setProfileState(null, false);
    })
    .catch(() => {
      actions.setProfileState('error', false);
    });
};

export const saveInterests = async ({ state, effects, actions }, interests) => {
  actions.setProfileState(null, true);
  if (state.profile.data == null) {
    state.profile.data = {};
  }
  state.profile.data.interests = interests;
  state.profile.data.onboarded = true;
  effects
    .saveUserProfile(state.user.uid, state.profile.data)
    .then(() => {
      actions.setProfileState(null, false);
      window.location = '/profile';
    })
    .catch(() => {
      actions.setProfileState('error', false);
    });
};

export const saveCountry = async ({ state, effects, actions }, country) => {
  actions.setProfileState(null, true);
  if (state.profile.data == null) {
    state.profile.data = {};
  }
  state.profile.data.country = country;
  effects
    .saveUserProfile(state.user.uid, state.profile.data)
    .then(() => {
      actions.incrementOnboardingIndex();
      actions.setProfileState(null, false);
    })
    .catch(() => {
      actions.setProfileState('error', false);
    });
};

export const saveBirthday = async ({ state, effects, actions }, birthday) => {
  actions.setProfileState(null, true);
  if (state.profile.data == null) {
    state.profile.data = {};
  }
  state.profile.data.birthday = birthday;
  effects
    .saveUserProfile(state.user.uid, state.profile.data)
    .then(() => {
      actions.incrementOnboardingIndex();
      actions.setProfileState(null, false);
    })
    .catch(() => {
      actions.setProfileState('error', false);
    });
};

export const setFeedback = ({ state }, feedback) => {
  state.feedback.data = feedback;
  state.feedback.fetching = false;
};

export const fetchFeedback = async ({ state, effects, actions }) => {
  state.feedback.fetching = true;
  effects.api
    .getFeedback()
    .then((response) => {
      actions.setFeedback(response);
    })
    .catch(() => {
      actions.setFeedback(null);
    });
};

export const saveFinishedCycle = async ({ effects }, reqData) => {
  effects.api.postFinishedCycle(reqData.cycle_id);
};

export const sendReminders = async ({ effects }) => {
  effects.api.sendReminders();
};

export const sendRemindersToNonLoggedUsers = async ({ effects }) => {
  effects.api.sendRemindersToNonLoggedUsers();
};
