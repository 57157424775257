import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import PropTypes from 'prop-types';

export default function Loader(props) {
    return (
        <div>
            <BeatLoader
                css={{
                    display: "block",
                    margin: "0 auto",
                    height: props.height,
                    width: props.width
                }}
                size={props.size}
                color={props.color}
                loading={true}
            />
        </div>
    );
}

Loader.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    size: PropTypes.number,
    color: PropTypes.string
}