export const ROUTE_SPLASH = '/';
export const ROUTE_ADMIN_QUESTIONNAIRE = '/admin-upitnici';
export const ROUTE_LOGIN = '/prijava';
export const ROUTE_ONBOARDING = '/onboarding';
export const ROUTE_REGISTER = '/registracija';
export const ROUTE_LOGIN_FORM = '/obrazac-za-prijavu';
export const ROUTE_REGISTER_FORM = '/obrazac-za-registraciju';
export const ROUTE_QUESTIONNAIRE = '/upitnik';
export const ROUTE_SIGNOUT = '/signout';
export const ROUTE_PRIVACY_POLICY = '/polica-privatnosti';
export const ROUTE_PARENT_CONSENT = '/privola-roditelja';
export const ROUTE_LOCK_SCREEN = '/potrebna-privola-roditelja';
export const ROUTE_FINAL_SCREEN = '/kraj';
export const ROUTE_CYCLE_ALREADY_FINISHED = '/upitnik-ispunjen';
export const ROUTE_NO_ACTIVE_SURVEY = '/nema-aktivnog-upitnika';
export const ROUTE_PASWORDLESS_SIGNIN = '/potrebna-verifikacija';
