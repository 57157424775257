import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'firebase';
import { useActions } from 'overmind/index';
import { useOvermindState } from 'overmind/index';
import PhoneNumber from 'components/PhoneNumber';
import QuestionContainer from 'components/QuestionContainer';
import SectionIntro from 'components/SectionIntro';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import ResultsContainer from 'components/ResultsContainer';
import Loader from 'components/atoms/Loader/Loader';
import {
  ROUTE_ADMIN_QUESTIONNAIRE,
  ROUTE_PRIVACY_POLICY,
  ROUTE_CYCLE_ALREADY_FINISHED,
  ROUTE_NO_ACTIVE_SURVEY,
} from 'routes';

export default function QuestionnairePage() {
  const [user, loading] = useAuthState(auth);
  const actions = useActions();
  const history = useHistory();
  const overmindState = useOvermindState();
  const [showedSection, setShowedSection] = useState(-1);
  const [sections, setSections] = useState([]);
  const [researchCycle, setResearchCycle] = useState(undefined);
  const [optionalSections, setOptionalSections] = useState([]);
  const [optionalQuestions, setOptionalQuestions] = useState([]);
  const [currentOptionalQuestionIx, setCurrentOptionalQuestionIx] = useState(
    []
  );
  const [filterForOptionalSection, setFilterForOptionalSection] = useState();

  const [currentQuestionNode, setCurrentQuestionNode] = useState([]);
  const [currentQuestionNodeIndex, setCurrentQuestionNodeIndex] = useState(0);
  const [seenQuestions, setSeenQuestions] = useState([]);
  const [nodeStack, setNodeStack] = useState([]);
  const [progressIndex, setProgressIndex] = useState(0);
  const [maxProgressIndex, setMaxProgressIndex] = useState();
  const [isOnFilterQuestion, setIsOnFilterQuestion] = useState(false);
  const [count, setCount] = useState(0);
  const [noFilterQuestions, setNoFilterQuestions] = useState([]);
  const [cycleId, setCycleId] = useState();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setCycleId(queryParams.get('cycleId'));
    if (!loading && user) {
      actions.fetchActiveSurvey(queryParams.get('cycleId'));
    }
  }, [user, loading, actions]);

  useEffect(() => {
    if (
      !overmindState.activeSurvey.data ||
      overmindState.activeSurvey.data.surveys.length == 0 ||
      overmindState.isUserAdmin
    )
      return;
    let cycleIsFinished = false;
    let activeSurveyId = overmindState.activeSurvey.data.surveys[0].id;
    cycleIsFinished =
      overmindState.finishedCycles.filter((e) => e === activeSurveyId).length >
      0
        ? true
        : false;
    if (
      cycleIsFinished &&
      !overmindState.adminSurvey.fetching &&
      !overmindState.isUserAdmin
    ) {
      history.replace(ROUTE_CYCLE_ALREADY_FINISHED);
    }
  }, [overmindState.activeSurvey.data, overmindState.activeSurvey.fetching]);

  useEffect(() => {
    actions.fetchIsUserAdmin();
  }, [overmindState.isUserAdmin]);

  useEffect(() => {
    if (user) {
      let timer = setTimeout(async () => {
        try {
          setCount((count) => count + 1);
          actions.setUser(user);
          user.getIdToken(true).then((token) => {
            window.localStorage.setItem('access_token', token);
          });
        } catch (e) {
          console.log(e);
        }
      }, 300000);
      return () => clearTimeout(timer);
    }
  }, [count]);

  useEffect(() => {
    setNoFilterQuestions([]);
  }, [overmindState.activeSurvey.data]);

  useEffect(() => {
    setCurrentOptionalQuestionIx(0);
    if (overmindState?.activeSurvey?.data?.surveys?.length > 0) {
      setResearchCycle(overmindState.activeSurvey.data.surveys[0]);
      const allSections = [];
      const allOptionalSections = [];
      const allQuestions = [];
      const allQuestionsInOptionalSection = [];
      const allNoFilterQuestions = [];

      overmindState.activeSurvey.data.surveys.forEach((survey) => {
        if (survey.sections) {
          survey.sections.forEach((section) => {
            if (!section.optional) {
              allSections.push(section);
              if (section.questions) {
                section.questions.forEach((singleQuestion) => {
                  allQuestions.push({
                    ...singleQuestion,
                  });
                  countingNoFilterQuestions(
                    allNoFilterQuestions,
                    singleQuestion
                  );
                });
              }
            } else {
              allOptionalSections.push(section);
              if (section.questions) {
                section.questions.forEach((singleQuestion) => {
                  allQuestionsInOptionalSection.push({
                    ...singleQuestion,
                  });
                });
              }
            }
          });
        }
      });
      setNoFilterQuestions(allNoFilterQuestions);
      setSections(allSections);
      setOptionalSections(allOptionalSections);
      setOptionalQuestions(allQuestionsInOptionalSection);
      setCurrentQuestionNode(allQuestions);
      setCurrentQuestionNodeIndex(0);
      setProgressIndex(0);
    }
  }, [
    overmindState.activeSurvey.data,
    cycleId,
    overmindState.adminSurvey.fetching,
  ]);

  useEffect(() => {
    setMaxProgressIndex(noFilterQuestions.length);
  }, [noFilterQuestions]);

  const countingNoFilterQuestions = (allNoFilterQuestions, question) => {
    if (!question.filter_question || question.filter_answer.startsWith('M')) {
      allNoFilterQuestions.push({
        ...question,
      });
    }
    if (question.filter_sub_questions.length > 0)
      question.filter_sub_questions.forEach((sub_question) =>
        countingNoFilterQuestions(allNoFilterQuestions, sub_question)
      );
    return allNoFilterQuestions;
  };

  const nextQuestion = (currAnswer, currQNode, currQNodeIx) => {
    if (currQNode == undefined) {
      currQNode = currentQuestionNode;
    }
    if (currQNodeIx == undefined) {
      currQNodeIx = currentQuestionNodeIndex;
    }

    if (currAnswer != undefined) {
      seenQuestions.push({
        node: currQNode,
        index: currQNodeIx,
        answer: currAnswer,
        progressIndex: progressIndex,
        isOnFilterQuestion: isOnFilterQuestion,
        id: currQNode[currQNodeIx].id,
      });
      setSeenQuestions(seenQuestions);
    }

    let currQuestion = currQNode[currQNodeIx];

    if (currQuestion.input_type == 'GENDER')
      setFilterForOptionalSection(currAnswer.value);

    if (
      currQuestion.filter_sub_questions.length > 0 &&
      currAnswer !== undefined
    ) {
      /* this is a filter question and we should check if any of the 
      sub-questions should appear after the current answer */
      let subFilterQuestions = currQuestion.filter_sub_questions.filter(
        (el) =>
          el.filter_answer == currAnswer.value ||
          el.filter_answer == currAnswer.text
      );

      if (subFilterQuestions.length > 0) {
        nodeStack.push({
          node: currQNode,
          index: currQNodeIx,
        });
        setNodeStack(nodeStack);
        setCurrentQuestionNodeIndex(0);
        setCurrentQuestionNode(subFilterQuestions);
        setIsOnFilterQuestion(true);
        if (currQuestion.input_type == 'GENDER')
          setProgressIndex(progressIndex + 1);
        return;
      }
    }

    let nextIndex = currQNodeIx + 1;
    while (currQNode[nextIndex] && currQNode[nextIndex].filter_question) {
      for (let seenQuestion of seenQuestions) {
        if (currQNode[nextIndex].filter_question === seenQuestion.id) {
          if (
            currQNode[nextIndex].filter_answer.startsWith('M') ||
            currQNode[nextIndex].filter_answer.startsWith('Ž')
          )
            setProgressIndex(progressIndex + 1);
          if (
            currQNode[nextIndex].filter_answer != null &&
            currQNode[nextIndex].filter_answer == seenQuestion.answer.value
          ) {
            setCurrentQuestionNode(currQNode);
            setCurrentQuestionNodeIndex(nextIndex);
            if (
              currQNode[nextIndex].filter_answer.startsWith('M') ||
              currQNode[nextIndex].filter_answer.startsWith('Ž')
            )
              setProgressIndex(progressIndex + 1);
            return;
          }
        }
      }
      nextIndex++;
    }

    if (nextIndex < currQNode.length) {
      if (!isOnFilterQuestion) setProgressIndex(progressIndex + 1);
      setCurrentQuestionNode(currQNode);
      setCurrentQuestionNodeIndex(nextIndex);
      return;
    }

    // recursively go back through nodes on stack
    let curr_node = nodeStack.pop();
    if (curr_node == undefined) {
      // there aren't any nodes on stack left
      // we set nodeIndex for the checks if there are any more questions left in the code below
      setNodeStack(nodeStack);
      setCurrentQuestionNode(currQNode);
      setCurrentQuestionNodeIndex(nextIndex);
      setIsOnFilterQuestion(false);
      setProgressIndex(progressIndex + 1);
      return;
    }

    setNodeStack(nodeStack);
    if (nodeStack.length == 0) {
      setIsOnFilterQuestion(false);
      setProgressIndex(progressIndex + 1);
    }

    // recursively check what is the next question
    nextQuestion(undefined, curr_node.node, curr_node.index);
  };

  const prevQuestion = (section) => {
    let curr_node = seenQuestions.pop();
    if (curr_node == undefined) {
      return;
    }
    if (curr_node.node[curr_node.index].section !== section) {
      setShowedSection(curr_node.node[curr_node.index].section);
      seenQuestions.push(curr_node);
      return;
    }
    if (currentQuestionNode[currentQuestionNodeIndex]) {
      if (curr_node.isOnFilterQuestion) {
        if (
          currentQuestionNode[currentQuestionNodeIndex].filter_question ===
            null ||
          (currentQuestionNode[currentQuestionNodeIndex].filter_question !==
            null &&
            currentQuestionNode[currentQuestionNodeIndex - 1] &&
            currentQuestionNode[currentQuestionNodeIndex].section !==
              currentQuestionNode[currentQuestionNodeIndex - 1].section)
        ) {
          nodeStack.push({
            node: currentQuestionNode,
            index: currentQuestionNodeIndex - 1,
          });
          setNodeStack(nodeStack);
        } else if (
          currentQuestionNode[currentQuestionNodeIndex].filter_question !== null
        ) {
          setCurrentQuestionNode(curr_node.node);
          setProgressIndex(curr_node.progressIndex);
          setIsOnFilterQuestion(curr_node.isOnFilterQuestion);
        }
      } else {
        if (
          currentQuestionNode[currentQuestionNodeIndex].filter_question !== null
        ) {
          nodeStack.pop();
          setNodeStack(nodeStack);
        }
      }
    }
    if (!curr_node.isOnFilterQuestion) {
      setCurrentQuestionNode(curr_node.node);
      setProgressIndex(curr_node.progressIndex);
    }
    setCurrentQuestionNodeIndex(curr_node.index);
    setIsOnFilterQuestion(curr_node.isOnFilterQuestion);
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  const getSectionById = (sectionId) => {
    let selectedSection;
    sections.forEach((section) => {
      if (section.id === sectionId && !section.optional) {
        selectedSection = section;
      }
    });

    return selectedSection;
  };

  const getOptionalSectionById = (sectionId) => {
    let selectedSection;
    optionalSections.forEach((section) => {
      if (section.id === sectionId && section.optional) {
        selectedSection = section;
      }
    });

    return selectedSection;
  };

  const filterOptionalQuestions = () => {
    let questions = optionalQuestions;
    let filteredQuestions = [];

    questions.forEach((question) => {
      if (
        !question.filter_answer ||
        question.filter_answer == filterForOptionalSection
      )
        filteredQuestions.push(question);
    });
    setOptionalQuestions(filteredQuestions);
  };

  const showFooter = (input_type) => {
    if (
      input_type === 'MONTH' ||
      input_type === 'DATE' ||
      input_type === 'NUMBER' ||
      input_type === 'DECIMAL' ||
      input_type === 'SCALE' ||
      input_type === 'TEXT' ||
      input_type === 'TWO_FIELD' ||
      input_type === 'MULTIPLE_SELECT'
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {overmindState.activeSurvey.fetching ? (
        <Loader />
      ) : (
        <>
          {overmindState.isUserAdmin && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              translate="no"
            >
              <Grid container direction="column">
                <h2>Ulogirani ste s administratorskim računom</h2>
                <h3>
                  Na <a href={ROUTE_ADMIN_QUESTIONNAIRE}>idućem linku</a> možete
                  odabrati proizvoljan upitnik
                </h3>
              </Grid>
            </Box>
          )}
          {(maxProgressIndex && maxProgressIndex > 0) ||
          (maxProgressIndex &&
            maxProgressIndex > 0 &&
            overmindState?.activeSurvey?.data?.surveys?.length > 0 &&
            overmindState.isUserAdmin) ? (
            <>
              <BorderLinearProgress
                variant="determinate"
                value={Math.min(1, progressIndex / maxProgressIndex) * 100}
              />
              {currentQuestionNodeIndex < currentQuestionNode.length &&
                showedSection !==
                  currentQuestionNode[currentQuestionNodeIndex].section && (
                  <>
                    <SectionIntro
                      showBack={seenQuestions.length !== 0}
                      showNext={true}
                      options={getSectionById(
                        currentQuestionNode[currentQuestionNodeIndex].section
                      )}
                      sectionOptional={false}
                      onNext={() =>
                        setShowedSection(
                          currentQuestionNode[currentQuestionNodeIndex].section
                        )
                      }
                      onBack={() => prevQuestion(showedSection)}
                    />
                  </>
                )}

              {currentQuestionNodeIndex < currentQuestionNode.length &&
                currentQuestionNode[currentQuestionNodeIndex] &&
                showedSection ===
                  currentQuestionNode[currentQuestionNodeIndex].section && (
                  <>
                    <QuestionContainer
                      showBack={seenQuestions.length !== 0}
                      showNext={true}
                      showFooter={showFooter(
                        currentQuestionNode[currentQuestionNodeIndex].input_type
                      )}
                      options={currentQuestionNode[currentQuestionNodeIndex]}
                      section={getSectionById(
                        currentQuestionNode[currentQuestionNodeIndex].section
                      )}
                      onNext={(answer) => {
                        nextQuestion(answer);
                      }}
                      onBack={() =>
                        prevQuestion(
                          currentQuestionNode[currentQuestionNodeIndex].section
                        )
                      }
                    />
                  </>
                )}
              {currentQuestionNodeIndex === currentQuestionNode.length &&
                (!optionalQuestions ||
                  (optionalQuestions &&
                    (currentOptionalQuestionIx < 0 ||
                      optionalQuestions[currentOptionalQuestionIx]?.section !==
                        showedSection))) &&
                researchCycle.ask_for_phone_number && (
                  <PhoneNumber
                    showBack={true}
                    showNext={true}
                    options={researchCycle}
                    user={user}
                    onNext={() =>
                      setCurrentQuestionNodeIndex(currentQuestionNodeIndex + 1)
                    }
                    onBack={() => prevQuestion(showedSection)}
                  />
                )}
              {(currentQuestionNodeIndex > currentQuestionNode.length ||
                (currentQuestionNodeIndex === currentQuestionNode.length &&
                  !researchCycle.ask_for_phone_number)) &&
                (!optionalQuestions ||
                  (optionalQuestions &&
                    (currentOptionalQuestionIx === optionalQuestions.length ||
                      currentOptionalQuestionIx < 0))) && (
                  <ResultsContainer
                    showBack={true}
                    showNext={true}
                    options={researchCycle}
                    user={user}
                    onBack={() => {
                      setCurrentOptionalQuestionIx(
                        currentOptionalQuestionIx - 1
                      );
                      setCurrentQuestionNodeIndex(currentQuestionNodeIndex - 1);
                    }}
                    // onNext={history.replace(ROUTE_FINAL_SCREEN)}
                  />
                )}

              {/* OPTIONAL SECTION */}
              {(currentQuestionNodeIndex > currentQuestionNode.length ||
                (currentQuestionNodeIndex === currentQuestionNode.length &&
                  !researchCycle.ask_for_phone_number)) &&
                optionalSections &&
                optionalQuestions &&
                optionalQuestions[currentOptionalQuestionIx] &&
                showedSection !==
                  optionalQuestions[currentOptionalQuestionIx].section && (
                  <>
                    <SectionIntro
                      showBack={false}
                      showNext={true}
                      options={getOptionalSectionById(
                        optionalQuestions[currentOptionalQuestionIx].section
                      )}
                      sectionOptional={true}
                      onNext={(next) => {
                        if (next) {
                          setShowedSection(
                            optionalQuestions[currentOptionalQuestionIx].section
                          );
                          filterOptionalQuestions();
                        } else {
                          setCurrentOptionalQuestionIx(-1);
                        }
                      }}
                      onBack={false}
                    />
                  </>
                )}
              {optionalQuestions &&
                optionalQuestions[currentOptionalQuestionIx] &&
                showedSection ===
                  optionalQuestions[currentOptionalQuestionIx].section && (
                  <QuestionContainer
                    showBack={true}
                    showNext={true}
                    showFooter={showFooter(
                      optionalQuestions[currentOptionalQuestionIx].input_type
                    )}
                    options={optionalQuestions[currentOptionalQuestionIx]}
                    section={getOptionalSectionById(
                      optionalQuestions[currentOptionalQuestionIx].section
                    )}
                    onNext={() =>
                      setCurrentOptionalQuestionIx(
                        currentOptionalQuestionIx + 1
                      )
                    }
                    onBack={() => {
                      if (
                        getOptionalSectionById(
                          optionalQuestions[currentOptionalQuestionIx].section
                        ) !==
                        getOptionalSectionById(
                          optionalQuestions[currentOptionalQuestionIx - 1]
                            .section
                        )
                      ) {
                        setCurrentOptionalQuestionIx(currentOptionalQuestionIx);
                        setShowedSection(
                          optionalQuestions[currentOptionalQuestionIx - 1]
                            .section
                        );
                      } else {
                        setCurrentOptionalQuestionIx(
                          currentOptionalQuestionIx - 1
                        );
                      }
                    }}
                  />
                )}
            </>
          ) : (
            <>
              {!loading &&
                !overmindState.adminSurvey.fetching &&
                !overmindState.isUserAdmin &&
                overmindState?.activeSurvey?.data?.surveys?.length == 0 &&
                history.replace(ROUTE_NO_ACTIVE_SURVEY)}
            </>
          )}
        </>
      )}
    </>
  );
}
