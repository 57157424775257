import React, { useState } from 'react';
import { useActions } from 'overmind/index';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function SectionIntro(props) {
  const { showBack, options, sectionOptional, showNext } = props;

  const renderSection = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid
          container
          direction="row"
          style={{
            maxWidth: '300px',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ marginBottom: '16px' }}>
            <span
              style={{
                fontSize: options.font_size,
                fontWeight: 800,
                color: options.color,
              }}
            >
              {options.intro}
            </span>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      xs={12}
      style={{
        height: '100vh',
        width: '100vw',
        padding: '10px',
        backgroundColor: options.background_color,
      }}
    >
      {/* Header and content */}
      <Grid item style={{ height: '100px' /*border: '1px solid red'*/ }}>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          translate="no"
        >
          <Grid
            container
            direction="column"
            style={{
              maxWidth: '840px',
              /*border: '1px solid green',*/
            }}
          >
            <Grid item style={{ height: '64px' }}>
              {showBack && (
                <div
                  onClick={() => props.onBack && props.onBack()}
                  style={{
                    height: '44px',
                    width: '44px',
                    border: '2px solid ' + options.color,
                    borderRadius: '22px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ArrowBackIcon
                    style={{ color: options.color }}
                    sx={{ fontSize: 32 }}
                  />
                </div>
              )}
            </Grid>
            <Grid item>
              <div style={{ paddingTop: '80px' }}>
                <span
                  style={{
                    fontSize: '34px',
                    fontWeight: 800,
                    color: options.color,
                  }}
                >
                  {options.name}
                </span>
              </div>
            </Grid>

            <Grid item>
              <div style={{ paddingTop: '20px' }}>{renderSection()}</div>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* Footer */}
      <Grid
        item
        style={{
          marginBottom: '20px',
          /*border: '1px solid red',*/
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          <Grid
            container
            direction="column"
            style={{
              maxWidth: '540px',
              /*border: '1px solid green',*/
            }}
          >
            <Grid item style={{ height: '110px' }}>
              {showNext && (
                <div
                  onClick={() => {
                    props.onNext && props.onNext(true);
                  }}
                  style={{
                    height: '44px',
                    width: '100%',
                    border: '2px solid ' + options.color,
                    borderRadius: '22px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: options.color,
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  <span>Započni</span>
                </div>
              )}
            </Grid>

            {sectionOptional && (
              <Grid item style={{ height: '110px' }}>
                {showNext && (
                  <div
                    onClick={() => {
                      props.onNext && props.onNext(false);
                    }}
                    style={{
                      height: '44px',
                      width: '100%',
                      border: '2px solid ' + options.color,
                      borderRadius: '22px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: options.color,
                      color: 'white',
                      cursor: 'pointer',
                    }}
                  >
                    <span>Završi</span>
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
